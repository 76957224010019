import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { useAccount } from "wagmi";
import { useDispatch } from "react-redux";

export default function Table() {
  let user_type = localStorage.getItem("type");
  let auth = localStorage.getItem("auth");
  const dispatch = useDispatch();
  const [disputedOrders, setDisputedOrders] = useState([]);

  const { address, connector, isConnected } = useAccount();

  const getDisputedOrders = async () => {
    await axios
      .get(
        `https://web3backend.meinstein.ai/api/payment/${user_type?.toLowerCase()}/get_with_paginate?limit=10&currentPage=1&status=dispute`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        }
      )

      .then(async (response) => {
        if (response?.data?.data?.data) {
          setDisputedOrders(response.data.data.data);
        }
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    getDisputedOrders();
  }, []);

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Data Seller / Community
        </p>

        <div className="bg-white dark:bg-[#2B3674] w-full rounded-[20px] px-8 py-5 mt-10">
          <div className="flex justify-between items-center">
            <h3 className="text-2xl text-[#1B2559] dark:text-white font-bold">
              Disputes
            </h3>
          </div>

          <div className="mt-5 overflow-x-auto">
            <div className="border-b border-[#E9EDF7] flex items-center justify-between pb-2">
              <div className="min-w-[980px]">
                <div className="w-full flex justify-around text-[#A3AED0] tracking-[-0.02em] leading-6 text-sm font-medium ml-3">
                  <div className="flex items-center">
                    <p>Type</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Order ID </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Price </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Fees</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Status</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Date</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[1150px]">
              <table className="table-fixed border-separate border-spacing-y-2 w-full">
                <tbody className="text-[#2B3674] dark:text-white tracking-[-0.02em] leading-6 text-sm font-bold text-center">
                  {disputedOrders.length ? (
                    <>
                      {disputedOrders.map((data, index) => (
                        <tr key={index}>
                          <td>
                            {data.seller == address ? "SELL" : ""}
                            {data.buyer == address ? "BUY" : ""}
                          </td>
                          <td>{data.order_id}</td>
                          <td>{data.price}</td>
                          <td>{data.fee}</td>
                          <td>{data.status}</td>
                          <td>
                            {(data.updatedAt || data.updated_at).split("T")[0]}
                          </td>
                          <a
                            href={`https://testnet.bscscan.com/tx/${data.trx}`}
                            target="_blank"
                            className="flex bg-[#11047A] text-white font-medium h-[30px] items-center justify-center cursor-pointer w-[93px] rounded-full"
                          >
                            Detail
                          </a>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p className="text-center font-semibold py-6 w-full text-[#161319] dark:text-white">
                      No Disputes Found
                    </p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
