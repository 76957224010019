import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
// import TableGDT from "../components/TableGDT"
import TopContractsBuyer from "../components/TopContractsBuyer";
import TopContractsSeller from "../components/TopContractsSeller";
import axios from "axios";
import { useDispatch } from "react-redux";

export default function DataTracker() {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  const [isBuyer, setIsBuyer] = useState();
  const [contracts, setContracts] = useState([]);

  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType === "Buyer") {
      setIsBuyer(true);
      getOrdersForBuyer();
    } else {
      setIsBuyer(false);
      getOrdersForSeller();
    }
  };

  const daysAgoFunction = async (dateString) => {
    const dateObject = await new Date(dateString);
    const currentDate = await new Date();

    const timeDifference = currentDate - dateObject;
    const daysDifference = await Math.floor(
      timeDifference / (1000 * 60 * 60 * 24)
    );
    console.log(daysDifference);

    return daysDifference;
  };

  const getOrdersForBuyer = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      // url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForBuyer`,
      url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForBuyer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        if (response.data.data) await setContracts(response.data.data);
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        await dispatch({ type: "loading", payload: false });
      });
  };

  const getOrdersForSeller = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForSeller`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        let allOrders = [];
        for (let i = 0; i < response.data.data.length; i++) {
          allOrders[i] = response.data.data[i];
          allOrders[i].daysAgo = await daysAgoFunction(response.data.data[i]?.orderDetails?.createdAt)
        }
        await setContracts(allOrders);
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    checkType();
  }, []);

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-slate-700">
          <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
            <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
              Data Seller / Community
            </p>
            {/* <TableGDT /> */}
            <h3 className="text-2xl tracking-[-0.02em] font-bold mt-14">
              Global Contracts
            </h3>
            {isBuyer ? <TopContractsBuyer contracts={contracts} /> : <TopContractsSeller contracts={contracts} />}
          </div>
        </div>
      </div>
    </>
  );
}
