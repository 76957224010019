import * as React from "react";
import { usePrepareContractWrite, useContractWrite } from "wagmi";
import { ethers } from "ethers";
import Web3 from "web3";
import SellerContract from "../abi/SellerContract.json";
import { useDispatch } from "react-redux";

export function SellDataPackage(props) {
  const dispatch = useDispatch();
  const [errorMsg, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [values, setValues] = React.useState({
    dataHash: "",
    signatureData: "",
  });

  function toPlainString(num) {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }

  let amount = props.price > 0 ? parseFloat(props.price) * 1e18 : 0;

  const generateHash = async () => {
    if (props.dataPackage && props.price) {
      const web3 = new Web3();
      const privateKey =
        "0x2563eaf2a274a6e0e0cfc8d0bb44372f13c886173260344182315aad0d78c91e";
      const dataHash = props.dataPackage.data_hash;
      let price = await parseInt(amount);

      if (typeof price === "number") {
        const messageHash = await web3.utils.soliditySha3(
          dataHash,
          web3.utils.toBigInt(price),
          10
        );

        const signatureData = await web3.eth.accounts.sign(
          messageHash,
          privateKey
        );

        setValues({
          ...values,
          dataHash: dataHash,
          signatureData: signatureData?.signature,
        });
      }
    }
  };

  React.useEffect(() => {
    generateHash();
  }, [props.price]);

  if (amount) {
    amount = toPlainString(amount);
    amount = ethers.BigNumber.from(amount);
  }

  const { config } = usePrepareContractWrite({
    address: "0xaf010E3F8aF28E20683FF104Dc5015D3D174266F",
    abi: SellerContract.abi,
    functionName: "createOrder",
    overrides: {
      from: props.address,
      value: "0",
    },
    args: [values.dataHash, amount, 10, values.signatureData],
  });
  const { data, isSuccess, error, write } = useContractWrite(config);

  React.useEffect(() => {
    console.log(isSuccess, data);
    if (isSuccess) {
      dispatch({ type: "loading", payload: false });
      setSuccess("Order Created Succesfully");
      setTimeout(() => {
        setSuccess("");
      }, 15000);
    }
  }, [isSuccess]);

  const writeFunction = async () => {
    try {
      await dispatch({ type: "loading", payload: true });
      await write();
      setError("");
      await setTimeout(() => {
        dispatch({ type: "loading", payload: false });
      }, 5000);
    } catch (error) {
      console.log(error);
      await dispatch({ type: "loading", payload: false });
      await setError("Insufficient balance for this transaction.");
      await setTimeout(() => {
        setError("");
      }, 15000);
    }
    props.setShowSellModal(false);
  };

  return (
    <>
      <button
        onClick={writeFunction}
        className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
      >
        Sell
      </button>

      {errorMsg ? (
        <div class="bg-red-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p class="text-center">{error}</p>
        </div>
      ) : null}

      {success ? (
        <div class="bg-green-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p class="text-center">{success}</p>
        </div>
      ) : null}
    </>
  );
}
