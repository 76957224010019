import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function Partners() {

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <div className="bg-[#f4f7fe] pb-20 min-h-screen w-full dark:bg-slate-700">
          <div className="mt-[31px] px-5 text-[#2B3674] dark:text-white mx-auto container">
            <Link to="/dashboard" className="flex items-center"><MdOutlineKeyboardBackspace className="mr-3" /> Back to Dashboard</Link>
            <h1 className="text-[40px] my-5 text-center">mEinstein & Gulp Data</h1>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">About Gulp Data:</h3>
              <p>Gulp Data specializes in data valuations, data-backed loans, and data monetization services. Founded in 2021, Gulp Data leverages proprietary machine learning to assess the market value of data quickly, enabling businesses to make informed decisions about their data assets.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Services Provided:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Data Valuation: </span> Gulp Data performs rapid valuations by comparing datasets to market comps, allowing companies to understand the financial worth of their data within a short timeframe.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Data-Backed Loans: </span> They offer non-dilutive loans using data as collateral, helping companies access capital without affecting their equity, making it especially appealing to startups and SMEs.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Data Monetization: </span> Gulp Data assists companies in creating high-margin, recurring revenue streams by licensing their data. This service taps into a network of potential buyers interested in unique datasets.</p>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Use Cases:</h3>
              <ol className="list-disc ml-5">
                <li><span className="font-semibold">Mergers and Acquisitions (M&A): </span> Data valuations are used to inform business transactions, ensuring that data assets are accurately represented.</li>
                <li><span className="font-semibold">Credit Enhancement: </span> Data-driven underwriting solutions help transform credit portfolios.</li>
              </ol>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Achievements and Partnerships:</h3>
              <ol className="list-disc ml-5">
                <li><span className="font-semibold">Partnerships: </span> Gulp Data has formed strategic alliances with major cloud providers like Snowflake and global consulting firms, enhancing their service offerings and reach.</li>
                <li><span className="font-semibold">Milestones: </span> The company has conducted thousands of data valuations and helped over 500 companies unlock significant data revenue. They also secured a $25 million lending facility to expand their data-backed loan services.</li>
              </ol>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">mEinstein and Gulp Data Partnership:</h3>
              <p>mEinstein and Gulp Data are forging a partnership on two fronts:</p>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Onboarding Gulp Data Customers: </span> Existing Gulp Data customers, such as Walmart, can use mEinstein’s data marketplace platform to directly connect with consumers, get consent-backed data and analytics, and create customer intelligence platforms.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Customized Marketplace: </span> A tailored mEinstein marketplace for Gulp Data customers, enabling them to monetize their data with open market principles.</p>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">3. Limitation of Liability</h3>
              <p>mEinstein, its affiliates, and its service providers are not responsible for examining or evaluating the quality, authenticity, legality, or any other aspect of the data sold by Sellers or purchased by Buyers through the Marketplace. Consequently, mEinstein shall not be liable for any claims, disputes, damages, or losses that may arise from transactions or interactions initiated within the Marketplace environment. This limitation of liability extends to any issues related to the accuracy of listings, the ability of Sellers to sell data, or the ability of Buyers to pay for data.</p>
            </div>
            <h1 className="text-[40px] my-5 text-center">mEinstein & AEA Advisors:</h1>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">About AEA Advisors:</h3>
              <p>AEA Investors is a private equity firm based in New York, founded in 1968. It specializes in middle market and small business investments across several sectors, including consumer and services.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Portfolio Companies in Services Sector:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">American Expediting: </span> Provides nationwide courier and logistics services.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Connexus Resource Group: </span> Specializes in business process outsourcing.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Montway Inc.: </span> A leading car shipping company.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Scan Global Logistics: </span> Offers global logistics and freight forwarding services.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Troxell Communications, Inc.: </span> Provides education technology solutions.</p>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Portfolio Companies in Consumer Sector:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">50 Floor Inc.: </span> Offers in-home flooring sales and installation services.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Barnet Products Corporation: </span> Supplies specialty ingredients to the personal care industry.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Implus Footcare LLC: </span> Manufactures and distributes footcare and fitness accessories.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Phillips Pet Food & Supplies: </span> A major distributor of pet food and supplies.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Jack's Family Restaurants: </span> Operates a chain of fast-food restaurants.</p>
                </li>
              </ul>
              <p>AEA Investors focuses on identifying companies with potential for operational, strategic, and financial improvements, supporting their growth through active management and partnerships.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">mEinstein and AEA Partnership:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">AI Marketplace Registration: </span> Portfolio companies register as buyers in mEinstein's AI marketplace to connect directly with consumers for their data.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Tailored Solutions: </span> Portfolio companies swiftly develop tailored AI and monetization solutions with their existing data domains (e.g. sales, supply chain, weather, marketing, shipping, transportation etc.) to enhance customer insight for retail, supply chain, engagement, outreach, and conversion rates on the mEinstein platform.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Data Monetization: </span> Portfolio companies monetize this data further with their partner ecosystem or new business models.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Key Performance Indicators: </span>AEA leadership receives important KPIs across portfolio companies for decision making.</p>
                </li>
              </ul>
            </div>
            <h1 className="text-[40px] my-5 text-center">mEinstein & Chubb:</h1>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">About Chubb:</h3>
              <p>Chubb is a leading global insurance company known for its comprehensive coverage and risk management solutions. Chubb invests millions of dollars annually to source consumer data crucial for their insurance business. However, with increasing regulations on user privacy and control, the value of the data they purchase has been rapidly diminishing.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Services and Challenges:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Consumer Data Sourcing: </span> Chubb spends significant resources to acquire consumer data to enhance their insurance offerings and risk assessments.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Regulatory Impact: </span> The growing emphasis on user privacy and data control has led to a decrease in the value and availability of third-party data, challenging Chubb's traditional data sourcing methods.</p>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">mEinstein and Chubb Partnership:</h3>
              <p>AEA Investors focuses on identifying companies with potential for operational, strategic, and financial improvements, supporting their growth through active management and partnerships.</p>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Consent-Backed Data and Analytics: </span> Chubb is highly interested in mEinstein's platform, where they can source data directly from users with their consent. This approach ensures compliance with privacy regulations and enhances data accuracy and reliability.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Proactive Risk Management: </span> For instance, Chubb can use the mEinstein platform to obtain specific details about users' water heaters, which are critical in managing basement flooding risks. Basement flooding insurance is a $40 billion industry, and having direct user data allows Chubb to proactively manage and tailor their basement insurance products.</p>
                </li>
              </ul>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Partnership Benefits:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Regulatory Compliance: </span> By sourcing data directly from users with consent, Chubb can navigate the stringent privacy regulations effectively.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Enhanced Data Quality: </span> Direct user data from mEinstein provides more accurate and valuable insights, improving risk assessment and insurance product development.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Cost Efficiency: </span> Partnering with mEinstein can potentially reduce the high costs associated with traditional data sourcing methods.</p>
                </li>
              </ul>
              <p>By collaborating with mEinstein, Chubb aims to transform its data acquisition strategy, ensuring compliance with privacy laws while obtaining high-quality, actionable data to enhance their insurance offerings and risk management capabilities.</p>
            </div>
            <h1 className="text-[40px] my-5 text-center">mEinstein & DTN:</h1>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">About DTN:</h3>
              <p>DTN is a leading provider of data, analytics, and technology solutions designed to help businesses make informed decisions. DTN serves a variety of industries, including agriculture, aviation, marine, mining, refined fuels, sports, shipping, transportation, utilities, and renewable energy. Their products deliver real-time and long-range forecasts, weather intelligence, and operational insights to optimize operations, manage risks, and improve efficiency. DTN's solutions include market data, weather forecasts, and specialized software to support operational and strategic planning.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Services Provided:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Real-Time and Long-Range Forecasts: </span> DTN offers precise weather forecasts and market data that help businesses plan and execute their operations efficiently.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Weather Intelligence: </span> DTN provides actionable weather intelligence that helps industries manage risks and optimize their operations.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Operational Insights: </span> Through specialized software and data analytics, DTN supports strategic and operational decision-making across various industries.</p>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">mEinstein and DTN Partnership:</h3>
              <p>DTN and mEinstein are exploring a partnership that leverages mEinstein's platform to enhance DTN's offerings and create new opportunities:</p>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Marketplace Integration: </span> DTN can offer its data and analytics products directly in the mEinstein marketplace. This integration allows DTN to reach a broader audience and offer their services to users looking for high-quality, real-time data.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Cross-Domain Data Analytics: </span> DTN can utilize the mEinstein platform to combine cross-domain data from different sectors such as agriculture, aviation, shipping, weather, and marine. By integrating this data, DTN can develop sophisticated analytics and AI models, creating comprehensive solutions like a sophisticated supply-chain offering for the agriculture industry.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Consumer Data Integration: </span> DTN can enhance their analytics by combining cross-domain data with consumer data sourced directly from mEinstein users. This integration can lead to the development of advanced analytics and AI models for industries such as global supply chains in the appliance industry.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Client Solutions Enhancement: </span> DTN can use the mEinstein platform to enable their clients to merge their internal data with DTN’s data. This synergy can create sophisticated solutions and platforms tailored to specific needs, such as customer intelligence platforms, supply chain platforms, and retail forecasting platforms.</p>
                </li>
              </ul>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Partnership Benefits:</h3>
              <ul className="list-decimal ml-5">
                <li className="mt-5">
                  <p><span className="font-semibold">Expanded Reach: </span> By offering products in the mEinstein marketplace, DTN can expand its reach and market presence.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Advanced Analytics: </span> Combining DTN's cross-domain data with consumer data enables the creation of more sophisticated and monetizable analytics and AI models.</p>
                </li>
                <li className="mt-5">
                  <p><span className="font-semibold">Customized Client Solutions: </span> DTN's clients can leverage mEinstein to integrate DTN's data with their own, creating tailored, high-value solutions that improve operational efficiency and strategic planning.</p>
                </li>
              </ul>
              <p>By partnering with mEinstein, DTN aims to enhance its data and analytics offerings, creating new revenue streams and providing more value to its clients through advanced, integrated solutions.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
