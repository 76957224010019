import * as React from "react";
import { usePrepareContractWrite, useContractWrite } from "wagmi";
import SellerContract from "../abi/SellerContract.json";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

export function BuyDataPackage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  function toPlainString(num) {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }

  let amount = toPlainString(props.price);
  amount = ethers.BigNumber.from(amount);

  const { config } = usePrepareContractWrite({
    address: "0xaf010E3F8aF28E20683FF104Dc5015D3D174266F",
    abi: SellerContract.abi,
    functionName: "purchaseOrder",
    overrides: {
      from: props.address,
      value: amount,
    },
    args: [props.orderId],
  });
  const { data, isSuccess, error, write } = useContractWrite(config);

  React.useEffect(() => {
    console.log(isSuccess, data);
    if (isSuccess) {
      dispatch({ type: "loading", payload: false });
      navigate(`/contract/download/${props.orderId}`);
    }
  }, [isSuccess]);

  const writeFunction = async () => {
    try {
      await dispatch({ type: "loading", payload: true });
      await write();
      setError("");
      await setTimeout(() => {
        dispatch({ type: "loading", payload: false });
      }, 5000);
    } catch (error) {
      console.log(error);
      await dispatch({ type: "loading", payload: false });
      await setError("Insufficient balance for this transaction.");
      await setTimeout(() => {
        setError("");
      }, 15000);
    }
  };

  return (
    <>
      <button
        onClick={writeFunction}
        className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
      >
        Buy Data
      </button>

      {errorMsg ? (
        <div className="bg-red-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p className="text-center">{error}</p>
        </div>
      ) : null}

      {success ? (
        <div className="bg-green-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p className="text-center">{success}</p>
        </div>
      ) : null}
    </>
  );
}
