import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function UsePolicy() {

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <div className="bg-[#f4f7fe] pb-20 min-h-screen w-full dark:bg-slate-700">
          <div className="mt-[31px] px-5 text-[#2B3674] dark:text-white mx-auto container">
            <Link to="/dashboard" className="flex items-center"><MdOutlineKeyboardBackspace className="mr-3" /> Back to Dashboard</Link>
            <h1 className="text-[40px] my-5">mEinstein Acceptable Use Policy (AUP)</h1>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Welcome to mEinstein!</h3>
              <p>This Acceptable Use Policy (AUP) sets forth the guidelines and expectations for all users of the mEinstein platform to ensure a productive, respectful, and legally compliant community. By accessing and using mEinstein, you agree to adhere to this AUP in addition to our Terms and Conditions.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">General Use</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">Users of mEinstein agree to:</p>
                <ul className="list-disc ml-5">
                  <li>Use mEinstein services only for lawful purposes and in accordance with applicable laws and regulations.</li>
                  <li>Ensure that all activities conducted on mEinstein respect the rights and privacy of others.</li>
                  <li>Not engage in any actions that could harm mEinstein, its users, or its operational functionality.</li>
                </ul>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Prohibited Conduct</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">Users of mEinstein must not engage in any of the following behaviors. Please note that the examples provided are not exhaustive but serve to clarify what constitutes unacceptable behavior:</p>
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Illegal Activities:</span> Engaging in any activity that is against the law, such as distributing pirated software or sharing content that promotes criminal behavior.</li>
                  <li><span className="font-semibold">Harmful or Abusive Content:</span> Posting or sharing content that could cause physical, emotional, or psychological harm. This includes, but is not limited to:
                    <ul className="list-disc ml-5">
                      <li><span className="font-semibold">Bullying or Harassment:</span> Targeting individuals or groups with the intent to intimidate, upset, or harm. For example, sending threatening messages or sharing personal information without consent.</li>
                      <li><span className="font-semibold">Hate Speech:</span> Content that promotes hatred or violence against individuals or groups based on race, ethnicity, religion, gender, sexual orientation, disability, or any other characteristic. An example is using slurs or making derogatory comments about a specific group.</li>
                    </ul>
                  </li>
                  <li><span className="font-semibold">Violation of Privacy:</span> Invasively sharing or collecting information about others without their consent. This might include posting someone else's personal information online or engaging in doxxing (publicly sharing private details, typically with malicious intent).</li>
                  <li><span className="font-semibold">Spreading Malware:</span> Uploading or sharing files that contain viruses, trojan horses, worms, or any other malicious software designed to damage or gain unauthorized access to computers and data.</li>
                  <li><span className="font-semibold">Intellectual Property Infringement:</span> Sharing or distributing content that infringes on someone else's copyrights, trademarks, patents, or other intellectual property rights. For example, uploading copyrighted music, videos, or software without permission.</li>
                </ul>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Simplification and Explanation of Terms</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">To make our AUP more accessible, we’ve included explanations for some of the technical terms used:</p>
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">Malware:</span> Malicious software designed to harm, exploit, or perform unauthorized actions on a computer system.</li>
                  <li><span className="font-semibold">Copyright:</span> The act of publicly revealing previously private personal information about an individual or organization, usually through the internet, without their consent.</li>
                  <li><span className="font-semibold">Copyright:</span> A legal right granted to the creator of original works, including the right to copy, distribute, and adapt the work.</li>
                  <li><span className="font-semibold">Trademark:</span> A symbol, word, or words legally registered or established by use as representing a company or product.</li>
                </ul>
                <p className="mt-5">Users must not:</p>
                <ul className="list-decimal ml-5">
                  <li>Upload, post, transmit, or otherwise make available any content that is illegal, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
                  <li>Violate the intellectual property rights of others, including copyright, trademark, patent, trade secret, or other proprietary rights.</li>
                  <li>Transmit any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.</li>
                  <li>Engage in any activities that would violate the personal privacy rights of others, including but not limited to collecting and distributing information about Internet users without their permission, except as permitted by applicable law.</li>
                  <li>Use the service for sending unsolicited email (spam) or messages, including promotions and/or advertising of products or services.</li>
                  <li>Attempt to gain unauthorized access to the mEinstein platform, other accounts, computer systems, or networks connected to the mEinstein server through hacking, password mining, or any other means.</li>
                </ul>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Acceptable Uses and Community Contribution</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">In addition to outlining what is prohibited on the mEinstein platform, we believe it is equally important to highlight the positive actions and behaviors that contribute to our community's growth and health. Below are examples of acceptable uses that align with our values of respect, inclusivity, and collaboration.</p>
                <p className="mt-5">Acceptable Uses:</p>
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">Collaboration and Sharing:</span> Engaging with other users by sharing knowledge, offering constructive feedback, and participating in discussions that foster a supportive learning environment.</li>
                  <li><span className="font-semibold">Respectful Interaction:</span> Treating all users with respect, valuing diverse perspectives, and engaging in discussions thoughtfully and considerately.</li>
                  <li><span className="font-semibold">Creative Expression:</span> Using mEinstein to showcase your work, share your ideas, and express yourself creatively, as long as it respects the rights and sensitivities of others in the community.</li>
                  <li><span className="font-semibold">Constructive Feedback:</span> Providing feedback on the platform and contributions from other users in a manner that is constructive, aimed at improvement, and respectful of effort and intention.</li>
                  <li><span className="font-semibold">Compliance with Laws:</span> Using the platform in a way that is compliant with all applicable laws and regulations, ensuring that your actions are lawful and ethical.</li>
                </ul>
                <p className="mt-5">Encouraged Behaviors:</p>
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Participation in Community Initiatives:</span> Actively participating in community-driven projects, events, and discussions that aim to improve the platform and support its users.</li>
                  <li><span className="font-semibold">Promotion of Safety and Security:</span> Contributing to the safety and security of the platform by reporting suspicious activities, potential vulnerabilities, or content that violates this AUP.</li>
                  <li><span className="font-semibold">Supporting Fellow Users:</span>      Offering support, guidance, or assistance to fellow users, especially those who are new to the platform or seeking help.</li>
                  <li><span className="font-semibold">Advocacy for Inclusivity:</span> Championing an inclusive environment by being welcoming to users of all backgrounds, experiences, and perspectives.</li>
                 
                </ul>
                <p className="mt-5">By actively engaging in these acceptable uses and encouraged behaviors, users of mEinstein not only enrich their own experience but also contribute to a vibrant, productive, and respectful community. We encourage all users to embrace these principles in their interactions on the platform.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Data Use and Monetization: Compliance with Regulations</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">When using mEinstein, it is crucial to not only adhere to general data protection principles but also comply with specific data privacy laws applicable based on the geographical location of the data subjects. This includes, but is not limited to, the following regulations:</p>
                
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">General Data Protection Regulation (GDPR): </span> Applies to all entities that process personal data of individuals in the European Union, emphasizing the rights of individuals to control their personal data.</li>
                  <li><span className="font-semibold">California Consumer Privacy Act (CCPA): </span> Provides California residents with the right to know about the personal data collected about them and the purpose for which it is used.</li>
                  <li><span className="font-semibold">Other Local and International Laws:</span> Depending on your location and the location of your users, other data protection laws and regulations may apply. It is your responsibility to identify and comply with such laws.</li>
                  <li>Users must ensure that any data they monetize or share through mEinstein complies with all applicable laws and regulations regarding data privacy and protection.</li>
                  <li>Users agree to obtain all necessary consents and rights needed from data subjects before sharing or monetizing personal data on mEinstein.</li>
                </ul>
                <p className="mt-5">Users are expected to familiarize themselves with these regulations to ensure that any data handling, sharing, or monetization activities on mEinstein are fully compliant. This includes obtaining necessary consents from data subjects and respecting their rights under applicable laws.</p>
                <p className="mt-5">Monetization of Data on mEinstein</p>
                <p className="mt-5">The mEinstein platform may offer opportunities for users to monetize data, under strict adherence to data protection principles and laws. Here’s a brief overview of our process and safeguards:</p>
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Consent and Transparency: </span> Prior to monetizing data, users must ensure they have obtained explicit consent from data subjects, clearly explaining how their data will be used and monetized.</li>
                  <li><span className="font-semibold">Data Anonymization: </span> Whenever possible, data should be anonymized to protect the privacy of individuals. mEinstein provides tools and guidelines to help users anonymize data before it is shared or monetized.</li>
                  <li><span className="font-semibold">Secure Transactions: </span>      mEinstein employs advanced security measures to protect data during transactions, including encryption and secure data transfer protocols. We are committed to safeguarding the confidentiality and integrity of user data at all times.</li>
                  <li><span className="font-semibold">Ethical Considerations:</span> We encourage users to consider the ethical implications of data monetization and to engage in practices that respect user privacy and promote trust. mEinstein reserves the right to review and approve any data monetization activities to ensure they align with our ethical standards and policies.</li>
                 
                </ul>
                
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Enforcement</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">Violations of this AUP may result in immediate suspension or termination of your account, legal action, and/or cooperation with law enforcement authorities in investigating and prosecuting any criminal or civil breaches.</p>
                <p className="mt-5">Enforcement Procedure</p>
                <p className="mt-5">mEinstein is committed to maintaining a safe and respectful platform for all users. In the event of a policy violation, mEinstein will take the following steps to enforce this AUP:</p>
                
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">Initial Warning: </span> Users found to be in violation of the AUP for the first time will receive an initial warning via email, outlining the nature of the violation and steps to rectify the issue. Users will be given a specific timeframe to comply.</li>
                  <li><span className="font-semibold">Temporary Suspension: </span> Repeated violations or severe breaches of the AUP will result in a temporary suspension of the user's account. The duration of the suspension will depend on the severity of the violation and the user's history of previous violations.</li>
                  <li><span className="font-semibold">Account Termination:</span> In cases of extreme violations or where a user repeatedly violates the AUP after warnings and temporary suspensions, mEinstein reserves the right to terminate the user's account permanently. Such actions will be taken to protect the integrity of the platform and its user community.</li>
                  <li><span className="font-semibold">Legal Action and Cooperation with Law Enforcement:</span> For illegal activities or when required by law, mEinstein may take legal action against the violator and cooperate with law enforcement authorities in their investigations.</li>
                  
                </ul>
                <p className="mt-5">Reporting Violations</p>
                
                <p className="mt-5">mEinstein encourages the community to report any suspected violations of this AUP to ensure the platform remains a productive and respectful environment. To facilitate this:</p>
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Anonymous Reporting:  </span> Users have the option to report violations anonymously. We understand the importance of protecting the identities of those who report violations to encourage openness and ensure that users feel secure in coming forward with their concerns.</li>
                  <li><span className="font-semibold">How to Report: </span> To report a violation, please use our dedicated reporting tool accessible through the mEinstein platform or contact our support team directly via [support email (support@meinstein.ai) /feedback form in app under ”Contact Us”]. Provide as much detail as possible to help us take appropriate action.</li>
                  <li><span className="font-semibold">Follow-up and Feedback: </span>      While we may not be able to provide detailed feedback on the outcome of each report due to privacy concerns, please know that all reports are taken seriously and investigated thoroughly in accordance with our policies and procedures.</li>
                  
                 
                </ul>
                <p className="mt-5">mEinstein not only promotes transparency but also fosters a culture of accountability and safety among its users by detailing the steps taken during the enforcement of violations and offering an anonymous reporting mechanism. This approach demonstrates mEinstein's dedication to upholding the standards set forth in the AUP and encourages the community's active participation in maintaining a respectful and legally compliant platform.</p>
                <p className="mt-5">We encourage the mEinstein community to report any suspected violations of this AUP to our support team. We are committed to working closely with our users to keep mEinstein a productive, respectful, and legal platform.</p>
                
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Consequences of Violations</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">Understanding the potential consequences of violating our Acceptable Use Policy (AUP) is crucial for all mEinstein users. We are committed to maintaining a safe, respectful, and lawful environment and will take appropriate actions against those who violate our policies.</p>
                <p className="mt-5">Impact on User Data and Content:</p>
                
                
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">Content Removal: </span> If content violates our AUP, it may be removed or modified to comply with our guidelines. Users will receive notification of the specific reason for the removal unless such notification would violate the law or compromise an investigation.</li>
                  <li><span className="font-semibold">Account Suspension or Termination: </span> Severe or repeated violations may lead to the temporary suspension or permanent termination of your account. Suspended users will be informed about the duration of the suspension and steps, if any, to restore their account.</li>
                  <li><span className="font-semibold">Data Preservation: </span> In cases of account suspension or termination, users will have a limited timeframe to request a copy of their data, except where prohibited by law or if it poses a security or privacy risk.</li>
                  
                  
                </ul>
                <p className="mt-5">Appeal Process:</p>
                
                <p className="mt-5">We understand that misunderstandings or errors can occur, and we provide an appeal process for users who believe their content was removed or their account was suspended or terminated in error.</p>
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Submitting an Appeal: </span> Users can submit an appeal by contacting our support team through the provided contact channels. Please include your username, the action taken against your account, and why you believe this action was incorrect.</li>
                  <li><span className="font-semibold">Review Process:</span> Our team will review your appeal, considering all relevant information and the context of the original violation. This process typically takes up to [specified time frame], but complex cases may require more time.</li>
                  <li><span className="font-semibold">Decision and Communication:  </span>      Once a decision has been made, we will communicate this to you, providing reasons for our decision and, if applicable, steps to restore your account or content.</li>
                  <li><span className="font-semibold">Final Decision:   </span>      While we ensure a thorough review, the outcome of the appeal is final. We encourage users to consider our feedback and guidance to prevent future violations.</li>
                  
                 
                </ul>
                
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Importance of Compliance</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">Compliance with our AUP ensures that mEinstein remains a productive, respectful, and legal platform. Violations not only affect the violating user but can also impact the community and the platform's integrity. We urge all users to review and adhere to our AUP carefully.</p>
                <p className="mt-5">By providing clear consequences for violations and a transparent appeal process, we aim to balance enforcement with fairness, ensuring users understand the impact of their actions and have a pathway to resolve disputes. This approach fosters a trustful relationship between mEinstein and its users, promoting a healthy and engaging platform for all.</p>
                
                
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Revision and Updates</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">mEinstein reserves the right to modify this AUP at any time without prior notice. Users are encouraged to review this policy periodically to ensure their activities align with the latest guidelines.</p>
                <p className="mt-5">Policy Updates and User Notification</p>
                <p className="mt-5">At mEinstein, we understand the importance of keeping our users informed about changes to our policies, including this Acceptable Use Policy (AUP). Our platform evolves continuously, and occasionally, updates to this AUP are necessary to reflect changes in our services, legal requirements, or user feedback.</p>
                <p className="mt-5">Revision and Updates</p>
                
                
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">Regular Reviews: </span> mEinstein will regularly review and update this AUP as necessary to ensure it remains relevant and effective in protecting both our users and our platform.</li>
                  <li><span className="font-semibold">Immediate Effect: </span> Updates to this AUP will take effect immediately upon posting on our website or platform. We encourage our users to review the AUP periodically to stay informed of any changes.</li>
                 
                  
                  
                </ul>
                <p className="mt-5">Communication of Changes</p>
                
              
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Active Communication:</span> When we make significant changes to this AUP, we are committed to actively communicating these changes to our users. This communication may take the form of email notifications, in-app messages, or announcements posted on our website or through social media channels.</li>
                  <li><span className="font-semibold">Highlighting Significant Changes: </span> In our communications, we will highlight significant changes to the AUP and explain how these may affect your use of the mEinstein platform. We aim to provide clear, understandable summaries of the key changes and their implications.</li>
                  <li><span className="font-semibold">Feedback and Questions:   </span>      We welcome and encourage feedback on any of our policy updates. Users with questions or concerns about changes to this AUP can contact us directly through the provided contact information. We are committed to engaging with our users and considering their feedback in our policy development process.</li>
                  
                  
                 
                </ul>
                <p className="mt-5">User Responsibility</p>
                <p className="mt-5">By continuing to use mEinstein after these changes take effect, users agree to be bound by the revised AUP. We understand the importance of these policies in ensuring a safe, respectful, and legally compliant environment for all users. As such, we take our responsibility to communicate changes effectively and transparently very seriously.</p>
                <p className="mt-5">By committing to active and transparent communication about updates to the AUP, mEinstein ensures that users are well-informed and engaged with the platform's governance. This approach not only fosters trust between mEinstein and its users but also encourages a collaborative environment where users feel valued and heard.</p>
                
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Intellectual Property Rights</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">At mEinstein, we deeply respect the intellectual property rights of all creators and contributors. Our platform is designed to empower users to share, innovate, and monetize content while ensuring that all intellectual property is appropriately handled and protected. Here’s how we manage intellectual property rights in relation to user-generated content and data monetization:</p>
                <p className="mt-5">User-Generated Content</p>
                
      
                <ul className="list-decimal ml-5 mb-">
                  <li><span className="font-semibold">Ownership: </span> Users retain ownership of their intellectual property rights in the content they create and share on mEinstein. By posting content on our platform, users grant mEinstein a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, display, reproduce, modify (e.g., reformat, translate), and distribute their content in connection with mEinstein's services. This license allows us to facilitate the sharing and discovery of your content within the mEinstein community and beyond, in accordance with user settings and preferences.</li>
                  <li><span className="font-semibold">Respect for Rights: </span> Users must ensure that any content they post does not infringe upon the intellectual property rights of others. This includes not posting copyrighted material without permission from the copyright owner, unless such use is otherwise protected by fair use or another legal exemption.</li>
                 
                  
                  
                </ul>
                <p className="mt-5">Data Monetization</p>
                
              
                <ul className="list-decimal ml-5">
                  <li><span className="font-semibold">Compliance and Consent:</span> When engaging in data monetization activities, users are required to comply with all applicable intellectual property laws. This includes obtaining necessary permissions and rights for the use, sharing, or sale of data that may involve third-party intellectual property rights.</li>
                  <li><span className="font-semibold">Transparency and Control:  </span>  mEinstein provides tools and settings that enable users to control how their content and data are shared and monetized on the platform. We are committed to transparency in our data monetization practices, ensuring users have clear information and consent regarding how their content and data are used.</li>
                 
                  
                  
                 
                </ul>
                <p className="mt-5">Protection and Enforcement</p>
                <p className="mt-5">mEinstein actively protects intellectual property rights and responds promptly to reports of alleged infringement. Users who believe their intellectual property has been improperly used on mEinstein should contact us through our designated channels. We follow a comprehensive process to address such claims, including the removal of infringing content in accordance with applicable laws and our policies.</p>
                <p className="mt-5">Encouragement of Innovation</p>
                <p className="mt-5">We encourage a culture of innovation and creativity on mEinstein. Our platform's features and policies are designed to foster an environment where users can freely express themselves, share their creations, and benefit from their contributions, all while respecting and upholding the intellectual property rights of others.</p>
                <p className="mt-5">By integrating these principles into our platform, mEinstein aims to balance the vibrant exchange of ideas and content with the rigorous protection of intellectual property rights. We believe that respecting these rights is foundational to fostering a creative, respectful, and lawful community.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Contact Information</h3>
              <div className="mb-5 ml-5">
                <p className="mt-5">For any questions or concerns regarding this AUP, please contact us at [support@meinstein.ai / feedback form in app under “Contact Us”].</p>
                <p className="mt-5">By using mEinstein, you acknowledge that you have read, understood, and agreed to abide by this Acceptable Use Policy.</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
