export const Icons = {
  orderData: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_66_389)">
      <path d="M23.3334 3.33334H10.0001C8.16675 3.33334 6.68342 4.83334 6.68342 6.66668L6.66675 33.3333C6.66675 35.1667 8.15008 36.6667 9.98341 36.6667H30.0001C31.8334 36.6667 33.3334 35.1667 33.3334 33.3333V13.3333L23.3334 3.33334ZM10.0001 33.3333V6.66668H21.6667V15H30.0001V33.3333H10.0001Z" fill="#969696" />
    </g>
    <defs>
      <clipPath id="clip0_66_389">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>,

  activeOrderData: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_66_388)">
      <path d="M10.0001 3.33334C8.16675 3.33334 6.68342 4.83334 6.68342 6.66668L6.66675 33.3333C6.66675 35.1667 8.15008 36.6667 9.98341 36.6667H30.0001C31.8334 36.6667 33.3334 35.1667 33.3334 33.3333V13.3333L23.3334 3.33334H10.0001ZM21.6667 15V5.83334L30.8334 15H21.6667Z" fill="#7114D2" />
    </g>
    <defs>
      <clipPath id="clip0_66_388">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  dataContracts: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.6666 36.6667H8.33325C5.57183 36.6667 3.33325 34.4281 3.33325 31.6667V5.00001C3.33325 4.07954 4.07944 3.33334 4.99992 3.33334H28.3333C29.2537 3.33334 29.9999 4.07954 29.9999 5.00001V16.6667H36.6666V31.6667C36.6666 34.4281 34.428 36.6667 31.6666 36.6667ZM26.6667 33.3333V6.66667H6.66675V31.6667C6.66675 32.5871 7.41294 33.3333 8.33341 33.3333H26.6667ZM30 31.6667V20H33.3333V31.6667C33.3333 32.5871 32.5871 33.3333 31.6667 33.3333C30.7462 33.3333 30 32.5871 30 31.6667ZM10 10H20V20H10V10ZM13.3333 16.6667V13.3333H16.6666V16.6667H13.3333ZM10 21.6667H23.3333V25H10V21.6667ZM23.3333 26.6667H10V30H23.3333V26.6667Z" fill="#969696" />
  </svg>,
  activeDataContracts: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33325 36.6667H31.6666C34.428 36.6667 36.6666 34.4281 36.6666 31.6667V16.6667H29.9999V5.00001C29.9999 4.07954 29.2537 3.33334 28.3333 3.33334H4.99992C4.07944 3.33334 3.33325 4.07954 3.33325 5.00001V31.6667C3.33325 34.4281 5.57183 36.6667 8.33325 36.6667ZM30 31.6667V20H33.3333V31.6667C33.3333 32.5871 32.5871 33.3333 31.6667 33.3333C30.7462 33.3333 30 32.5871 30 31.6667ZM8.33325 10V20H18.3333V10H8.33325ZM8.33325 25V21.6667H24.9999V25H8.33325ZM8.33325 26.6667V30H24.9999V26.6667H8.33325ZM15.0001 13.3333H11.6667V16.6667H15.0001V13.3333Z" fill="#7114D2" />
  </svg>,

  globalContracts: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9999 36.6667C10.7949 36.6667 3.33325 29.205 3.33325 20C3.33325 10.795 10.7949 3.33334 19.9999 3.33334C29.2049 3.33334 36.6666 10.795 36.6666 20C36.6666 29.205 29.2049 36.6667 19.9999 36.6667ZM16.1833 32.7783C14.5389 29.2905 13.5864 25.517 13.3783 21.6667H6.76992C7.43252 26.9069 11.1232 31.2634 16.1833 32.7783ZM19.9999 32.92C18.1299 29.55 16.9683 25.7317 16.7166 21.6667H23.2833C23.0423 25.6158 21.9205 29.4609 19.9999 32.92ZM33.2299 21.6667H26.6216C26.4135 25.517 25.4609 29.2905 23.8166 32.7783C28.8767 31.2634 32.5673 26.9069 33.2299 21.6667ZM13.3783 18.3333H6.76992C7.43252 13.0931 11.1232 8.73658 16.1833 7.22168C14.5389 10.7095 13.5864 14.483 13.3783 18.3333ZM23.2816 18.3333C23.0412 14.3843 21.9199 10.5392 19.9999 7.08001C18.0794 10.5391 16.9575 14.3842 16.7166 18.3333H16.7183H23.2816ZM26.6216 18.3333C26.4135 14.483 25.4609 10.7095 23.8166 7.22168C28.8767 8.73658 32.5673 13.0931 33.2299 18.3333H26.6216Z" fill="#969696" />
  </svg>,
  activeGlobalContracts: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4201 3.53333C9.93063 4.70974 4.17739 10.7903 3.41675 18.3333H12.5451C12.8303 13.0552 14.5127 7.94768 17.4201 3.53333ZM12.5451 21.6667H3.41675C4.17739 29.2097 9.93063 35.2902 17.4201 36.4667C14.5127 32.0523 12.8303 26.9447 12.5451 21.6667ZM36.5834 18.3333H27.4551C27.1699 13.0552 25.4875 7.94768 22.5801 3.53333C30.0695 4.70974 35.8228 10.7903 36.5834 18.3333ZM22.5801 36.4667C30.0695 35.2902 35.8228 29.2097 36.5834 21.6667H27.4551C27.1699 26.9447 25.4875 32.0523 22.5801 36.4667ZM15.8834 21.6667H24.1167C23.8424 26.1658 22.4256 30.5207 20.0001 34.32C17.5746 30.5207 16.1577 26.1658 15.8834 21.6667ZM20.0001 5.67999C17.5746 9.47926 16.1577 13.8342 15.8834 18.3333H24.1167C23.8424 13.8342 22.4256 9.47926 20.0001 5.67999Z" fill="#7114D2" />
  </svg>
  ,
  disputes: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.9135 1.30667C37.5105 4.52638 38.3388 8.07266 38.3335 11.6667C38.3335 15.3883 37.4618 18.9067 35.9135 22.0267L33.1718 20.0333C34.3805 17.4099 35.0043 14.5551 35.0001 11.6667C35.0001 8.68 34.3451 5.845 33.1718 3.3L35.9135 1.30667ZM15 21.6667C9.475 21.6667 5 17.1917 5 11.6667C5 6.14168 9.475 1.66668 15 1.66668C20.525 1.66668 25 6.14168 25 11.6667C25 17.1917 20.525 21.6667 15 21.6667ZM1.66675 36.6667C1.66675 29.3029 7.63628 23.3333 15.0001 23.3333C22.3639 23.3333 28.3334 29.3029 28.3334 36.6667H25.0001C25.0001 31.1438 20.5229 26.6667 15.0001 26.6667C9.47723 26.6667 5.00008 31.1438 5.00008 36.6667H1.66675ZM21.6666 11.6667C21.6666 15.35 18.6833 18.3333 14.9999 18.3333C11.3166 18.3333 8.33325 15.35 8.33325 11.6667C8.33325 7.98335 11.3166 5.00002 14.9999 5.00002C18.6833 5.00002 21.6666 7.98335 21.6666 11.6667ZM31.6669 11.6667C31.6696 9.48472 31.2426 7.3236 30.4102 5.30667L27.6169 7.34C28.0933 8.7327 28.3354 10.1947 28.3336 11.6667C28.3349 13.1387 28.0922 14.6007 27.6152 15.9933L30.4102 18.0267C31.2426 16.0097 31.6696 13.8486 31.6669 11.6667Z" fill="#969696" />
  </svg>,
  activeDisputes: <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3335 11.6667C38.3388 8.07266 37.5105 4.52638 35.9135 1.30667L33.1718 3.3C34.3451 5.845 35.0001 8.68 35.0001 11.6667C35.0043 14.5551 34.3805 17.4099 33.1718 20.0333L35.9135 22.0267C37.4618 18.9067 38.3335 15.3883 38.3335 11.6667ZM5 11.6667C5 17.1917 9.475 21.6667 15 21.6667C20.525 21.6667 25 17.1917 25 11.6667C25 6.14168 20.525 1.66668 15 1.66668C9.475 1.66668 5 6.14168 5 11.6667ZM15.0001 23.3333C7.63628 23.3333 1.66675 29.3029 1.66675 36.6667H28.3334C28.3334 29.3029 22.3639 23.3333 15.0001 23.3333ZM30.4102 5.30667C31.2426 7.3236 31.6696 9.48472 31.6669 11.6667C31.6696 13.8486 31.2426 16.0097 30.4102 18.0267L27.6152 15.9933C28.0922 14.6007 28.3349 13.1387 28.3336 11.6667C28.3354 10.1947 28.0933 8.7327 27.6169 7.34L30.4102 5.30667Z" fill="#7114D2" />
  </svg>,




}
