import { useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import BuyerSignUp from "./pages/BuyerSignUp";
import ContractDetails from "./pages/ContractDetails";
import Dashboard from "./pages/Dashboard";
import DataPackages from "./pages/DataPackages";
import DataTracker from "./pages/DataTracker";
import Disputes from "./pages/Disputes";
import DownloadData from "./pages/DownloadData";
import ForgetPassword from "./pages/ForgetPassword";
import OrderCreation from "./pages/OrderCreation";
import OrderData from "./pages/OrderData";
import OrderEdit from "./pages/OrderEdit";
import Partners from "./pages/Partners";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import TermsCondition from "./pages/TermsCondition";
import Transactions from "./pages/Transactions";
import UsePolicy from "./pages/UsePolicy";

function App() {
  let accepted = localStorage.getItem("policyAccepted");
  const [themeToggle, setThemeToggle] = useState(false);
  const [policyAccepted, setPolicyAccepted] = useState(accepted);
  const generalReducers = useSelector((state) => state);
  const { loading } = generalReducers;
  const location = useLocation();
  const isSignInPage = location.pathname === "/" || "/buyerSignUp";
  const accept = async () => {
    localStorage.setItem("policyAccepted", "true")
    await setPolicyAccepted(true);
  }

  return (
    <>
      <div className={`${themeToggle && "dark"}`}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <SignIn
                themeToggle={themeToggle}
                setThemeToggle={setThemeToggle}
              />
            }
          ></Route>
          <Route
            exact
            path="/forgetPassword"
            element={
              <ForgetPassword
                themeToggle={themeToggle}
                setThemeToggle={setThemeToggle}
              />
            }
          ></Route>
          <Route
            exact
            path="/buyerSignUp"
            element={
              <BuyerSignUp
                themeToggle={themeToggle}
                setThemeToggle={setThemeToggle}
              />
            }
          ></Route>
          <Route
            exact
            path="/dashboard"
            element={
              <Dashboard
                themeToggle={themeToggle}
                setThemeToggle={setThemeToggle}
              />
            }
          ></Route>
          <Route
            exact
            path="/orderData"
            element={
              <OrderData
                themeToggle={themeToggle}
                setThemeToggle={setThemeToggle}
              />
            }
          ></Route>
          <Route
            exact
            path="/orderCreation"
            element={<OrderCreation />}
          ></Route>
          <Route
            exact
            path="/order/edit/:orderId"
            element={<OrderEdit />}
          ></Route>
          <Route
            exact
            path="/contract/:orderId"
            element={<ContractDetails />}
          />
          <Route
            exact
            path="/contract/download/:orderId"
            element={<DownloadData />}
          />
          <Route exact path="/transactions" element={<Transactions />}></Route>
          <Route exact path="/orders" element={<Transactions />}></Route>
          <Route exact path="/disputes" element={<Disputes />}></Route>
          <Route exact path="/dataPackages" element={<DataPackages />}></Route>
          <Route exact path="/globalContracts" element={<DataTracker />}></Route>
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route exact path="/acceptable-use-policy" element={<UsePolicy />}></Route>
          <Route exact path="/partnerships" element={<Partners />}></Route>
          <Route exact path="/terms-conditions" element={
            <>
              <div className="font-DMSans flex justify-end mx-auto">
                <div className="bg-[#f4f7fe] pb-20 min-h-screen w-full dark:bg-slate-700">
                  <div className="mt-[31px] px-5 text-[#2B3674] dark:text-white mx-auto container">
                    <Link to="/dashboard" className="flex items-center"><MdOutlineKeyboardBackspace className="mr-3" /> Back to Dashboard</Link>
                    <TermsCondition />
                  </div>
                </div>
              </div>
            </>
          }></Route>
          <Route
            exact
            path="/profile"
            element={
              <Profile
                themeToggle={themeToggle}
                setThemeToggle={setThemeToggle}
              />
            }
          />
        </Routes>
        {loading ? (
          <div className="fixed min-h-screen w-full top-0 left-0 bg-[rgb(0,0,0,0.6)] z-50 flex justify-center items-center">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-52 h-52 text-gray-200 animate-spin dark:text-gray-600 fill-[#856DA7]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        <div
          className={`z-20 fixed bottom-0 w-full bg-white ${isSignInPage ? 'dark:bg-[#070B17]' : 'bg-white dark:bg-[#2B3674]'}`}
        >
          <ul className="p-0 m-0 flex justify-center items-center lg:justify-end px-7 text-sm  max-sm:text-[9px] max-[360px]:text-[7px] max-sm:gap-1 text-slate-500 dark:text-slate-300">
            <li className="px-4 py-3 max-sm:px-1"><Link to="/partnerships">Partnerships</Link></li>
            <li className="px-4 py-3 max-sm:px-1"><Link to="/terms-conditions">Terms and Conditions</Link></li>
            <li className="px-4 py-3 max-sm:px-1"><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li className="px-4 py-3 max-sm:px-1"><Link to="/acceptable-use-policy">Acceptable Use Policy</Link></li>
          </ul>
        </div>
        {!policyAccepted
          ? <div className="relative flex justify-center">
            <div className="fixed top-0 mx-auto max-w-[600px] z-50 mt-[3vh] max-h-[90vh] overflow-y-scroll shadow-2xl rounded-2xl">
              <TermsCondition />
              <div className="flex justify-end items-center bg-white w-full px-3 py-2">
                <button className="min-w-max text-white bg-[#11047A] rounded-[47px] font-medium text-base leading-6 my-3 px-7 py-1" onClick={accept}>Accept</button>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
          : null
        }
      </div>
    </>
  );
}

export default App;
