import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { MdNightlight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SignIn(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
    remenberMe: false,
  });
  const [errorMsg, setError] = useState("");
  const [type, setType] = useState("Seller");

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeChecknox = (e) => {
    setValues({ ...values, [e.target.name]: e.target.checked });
  };

  const setDatainSession = async () => {
    const queryParams = new URLSearchParams(location.search);
    const auth = await queryParams.get("auth");
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/auth/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          if (response.data.success) {
            await localStorage.setItem("login", response.data.data.id);
            await localStorage.setItem(
              "address",
              response.data.data.walletAddress
            );
            await localStorage.setItem("type", type);
            await localStorage.setItem("auth", auth);
            await dispatch({ type: "loading", payload: false });
            await navigate("/dashboard");
          } else {
            setError(response.data.error);
            await dispatch({ type: "loading", payload: false });
          }
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  const isCredentailsRemember = async () => {
    if (type === "Seller") {
      let email = await localStorage.getItem("sellerLogin");
      let password = await localStorage.getItem("sellerPassword");
      if (email && password) setValues({ ...values, email: email, password: password });
    } else {
      let email = await localStorage.getItem("buyerLogin");
      let password = await localStorage.getItem("buyerPassword");
      if (email && password) setValues({ ...values, email: email, password: password });
    }
  };

  useEffect(() => {
    setDatainSession();
  }, [location.search]);

  useEffect(() => {
    isCredentailsRemember();
  }, [type]);

  const login = async (e) => {
    e.preventDefault();
    if (values.email && values.password) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "post",
        url: `https://web3backend.meinstein.ai/api/auth/login`,
        data: {
          email: values.email,
          password: values.password,
          user_type: type === "Seller" ? "seller" : "buyer",
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.data.data.status) {
            if (response.data.data) {
              await localStorage.setItem(
                "login",
                response.data.data.data.user_id
              );
              await localStorage.setItem(
                "address",
                response.data.data.data.walletAddress
              );
              await localStorage.setItem(
                "name",
                `${response.data.data.data.first_name} ${response.data.data.data.last_name}`
              );
              await localStorage.setItem("type", type);
              await localStorage.setItem(
                "auth",
                response.data.data.data.auth_token
              );
              if (values.remenberMe) {
                if (type === "Seller") {
                  localStorage.setItem("sellerLogin", values.email);
                  localStorage.setItem("sellerPassword", values.password);
                } else {
                  localStorage.setItem("buyerLogin", values.email);
                  localStorage.setItem("buyerPassword", values.password);
                }
              }
              await dispatch({ type: "loading", payload: false });
              await navigate("/dashboard");
            }
          } else {
            setError("No user found");
            await dispatch({ type: "loading", payload: false });
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        })
        .catch(async (error) => {
          if (error.response.data?.errorMessage?.message)
            setError(error.response.data?.errorMessage?.message);
          else setError("Invalid User");
          await dispatch({ type: "loading", payload: false });
          setTimeout(() => {
            setError("");
          }, 5000);
        });
    } else setError("Please fill all the fields");
  };

  return (
    <>
      <div className="bg-white dark:bg-[#070B17] font-DMSans">
        <div className="flex h-[95vh]  bg-[url('img/background.png')] bg-cover bg-center lg:bg-none">
          <div className="w-11/12 sm:w-3/4 mx-auto lg:w-1/2">
            <section className="h-[96%] lg:h-full flex flex-col justify-center  lg:block">
              <div className="px-6 max-[375px]:px-2 flex flex-col justify-center gap-6 h-5/6 sm:h-2/3 lg:h-[96%] text-gray-800">

                <div className="flex flex-col justify-center items-center  flex-wrap h-auto g-6 bg-white dark:bg-[#070B17] rounded-3xl lg:bg-transparent lg:rounded-none ">

                  <div className="sm:w-[410px] max-[769px]:w-64 max-[769px]:py-3 max-[769px]:pb-9 max-[375px]:w-64 max-[325px]:w-56">
                    <div className="flex flex-col mb-2">
                      <img
                        src="/img/logo1.png"
                        alt=""
                        className="w-[200px] mt-10 invisible dark:visible max-[769px]:w-24"
                      />
                      <img
                        src="/img/logo2.png"
                        alt=""
                        className="w-[200px] -mt-20 max-[769px]:-mt-10 dark:hidden max-[769px]:w-24"
                      />
                    </div>
                    <form>
                      <div className="flex flex-col items-center justify-center max-[1020px]:items-start lg:block">
                        <p className="text-2xl sm:text-[24px] sm:w-full font-normal text-[#2B3674] dark:text-white mb-5 max-sm:text-sm max-[375px]:text-[12px]">
                          Sign in to the mEinstein Marketplace.
                        </p>

                        <div className="flex justify-start mb-3">
                          <p
                            className={`px-4 ${type === "Seller"
                              ? "border-b-4 border-[#7114D2]"
                              : null
                              } text-[#2B3674] dark:text-white cursor-pointer`}
                            onClick={() => setType("Seller")}
                          >
                            Seller
                          </p>
                          <p
                            className={`px-4 ${type === "Buyer"
                              ? "border-b-4 border-[#7114D2]"
                              : null
                              } text-[#2B3674] dark:text-white cursor-pointer`}
                            onClick={() => setType("Buyer")}
                          >
                            Buyer
                          </p>
                        </div>
                        <p className="mb-7  text-[#969696] dark:text-gray-200 max-[769px]:text-xs max-[375px]:text-[11px]">Please login to continue to your account.</p>
                        {/* <!-- Email input --> */}

                        <div class="w-full relative rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none mb-5 max-[769px]:mb-1">

                          <input id="email" class="block px-6 py-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#2B3674] focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                          <label for="email" class="absolute text-sm duration-300 transform -translate-y-6
                          scale-75 top-2 z-10 origin-[0]  
                          px-6 peer-focus:px-2 peer-focus:text-[#2B3674] peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 
                          peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Email*</label>
                        </div>
                        {/* <!-- Password input --> */}
                        <div className="text-xs sm:text-sm mb-6 max-[769px]:mb-2 w-full font-medium leading-[14px] tracking-[-0.02em] text-[#2B3674] dark:text-white">
                          <div className="relative flex items-center  justify-center mt-6 max-[769px]:mt-5 border border-solid border-gray-300 bg-white dark:border-none dark:bg-slate-700 rounded-xl">
                            <input id="password" class="block px-6 py-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#2B3674] focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "
                              type={values.showPassword ? "text" : "password"}
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                            />
                            <label for="password" class="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-2 z-10 origin-[0]  px-6 peer-focus:px-2 peer-focus:text-[#2B3674] peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Password*</label>
                            <button
                              onClick={handleClickShowPassword}
                              className="text-[#A3AED0] mr-3"
                            >
                              {values.showPassword ? (
                                <BiHide size={20} />
                              ) : (
                                <BiShow size={20} />
                              )}
                            </button>
                          </div>
                          {type === "Buyer" ? (
                            <div className="w-full text-right text-xs sm:text-sm">
                              <p className="text-end mt-2 mb-0 text-[#2B3674] tracking-[-0.02em] leading-[26px] dark:text-gray-400">
                                <Link
                                  to="/forgetPassword"
                                  className="pl-1 text-[#856DA7] hover:text-[#856DA7] focus:text-[#856DA7] font-semibold transition duration-200 ease-in-out"
                                >
                                  Forget password
                                </Link>
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <div className="w-full flex justify-between items-center mb-6 text-xs sm:text-sm">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              className="form-check-input h-4 w-4 border border-gray-300 rounded-sm appearance-none bg-tranparent accent-[#856DA7] checked:appearance-auto checked:bg-[#856DA7] checked:border-[#856DA7] focus:outline-none transition duration-200 mt-1 align-top max-[769px]:align-middle  bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                              id="exampleCheck2"
                              name="remenberMe"
                              value={values.remenberMe}
                              onChange={handleChangeChecknox}
                            />

                            <label
                              className="form-check-label border-4 sm:border-2 border-transparent leading-5 tracking-[-0.02em] inline-block text-[#2B3674] dark:text-gray-400"
                              htmlFor="exampleCheck2"
                            >
                              Keep me logged in
                            </label>
                          </div>
                        </div>

                        {errorMsg ? (
                          <p className="text-sm text-center text-red-700 font-semibold">
                            {errorMsg}
                          </p>
                        ) : null}

                        <div className="w-full text-center text-xs sm:text-sm">
                          <button
                            type="button"
                            onClick={login}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#7114D2] rounded-2xl py-5 sm:my-5"
                          >
                            Sign In
                          </button>
                        </div>

                        {type === "Buyer" ? (
                          <div className="w-full text-center text-xs sm:text-sm max-[769px]:text-[10px]">
                            <p className="text-start mb-0 text-[#2B3674] tracking-[-0.02em] leading-[26px] dark:text-gray-400">
                              Not buyer registered yet?
                              <Link
                                to="/buyerSignUp"
                                className="pl-1 text-[#856DA7] hover:text-[#856DA7] focus:text-[#856DA7] font-semibold transition duration-200 ease-in-out"
                              >
                                Create an Account
                              </Link>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* {type === "Buyer" ? (
            <div className="hidden lg:flex flex-col items-center justify-center bg-cover lg:bg-[url('img/background.png')] mr-2 w-1/2 lg:space-y-10 space-y-7 text-gray-200 relative">
              <p className="w-3/4 font-extrabold text-center text-5xl leading-[50px] tracking-wide absolute top-[10%]">
                THE NEW WAY TO CONNECT WITH CONSUMERS
              </p>
              <p className="w-3/4 font-light text-center text-2xl absolute bottom-16 uppercase">
                Purchase consent-based data & intelligence directly from
                consumers.
              </p>
            </div>
          ) : (
          )} */}
          <div className="hidden relative lg:flex flex-col items-center justify-center bg-cover lg:bg-[url('img/background.png')]  mr-2  w-1/2 lg:space-y-10 space-y-7 text-gray-200 font-extrabold bg-center">
            <p className="w-[85%]  text-center  text-2xl capitalize font-extrabold absolute top-[24%]">
              Harness the true value and power of <br />your personal data and insights in  <br />the new era of AI and Web3
            </p>
          </div>
          <button className="fixed bottom-16 z-50 right-7" onClick={() => props.setThemeToggle(!props.themeToggle)}>
            <div className="theme_toggler p-3 text-white -rotate-45">
              <MdNightlight className="text-sm sm:text-xl" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
