import React from "react";
import { BiSearch, BiErrorCircle } from "react-icons/bi";
import { BsBell } from "react-icons/bs";
import { FaMoon, FaEthereum } from "react-icons/fa";

export default function SearchBar(props) {
  return (
    <>
      <div className="flex flex-col xl:flex-row lg:justify-between items-center mt-4 mb-[23px] space-y-5">
        <h2 className="font-bold lg:text-[34px] text-[24px] lg:text-left text-center leading-[42px] tracking-[-0.02em] text-[#2B3674] dark:text-white ml-2">
          {props.heading}
        </h2>
        <div className="flex flex-col md:flex-row md:items-center space-x-5 rounded-[30px] bg-white dark:bg-[#2B3674] shadow-lg px-[10px] py-2 w-fit">
          <div className="flex items-center justify-between space-x-5 lg:py-0 py-2 px-5 w-fit">
            <button onClick={() => props.setThemeToggle(!props.themeToggle)}>
              <FaMoon className="text-[#A3AED0] text-xl" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
