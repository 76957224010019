import React, { useEffect, useState } from "react";
import TableDP from "../components/TableDP";
import BuyerTableDP from "../components/BuyerTableDP";
import SideBar from "../components/SideBar";

export default function DataPackages() {
  const [isBuyer, setIsBuyer] = useState();

  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType == "Buyer") setIsBuyer(true);
    else setIsBuyer(false);
  };

  useEffect(() => {
    checkType();
  }, []);
  return (
    <>
      <div className="font-DMSans flex justify-end max-w-[1920px] mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-slate-700">
          {isBuyer ? <BuyerTableDP /> : <TableDP />}
        </div>
      </div>
    </>
  );
}
