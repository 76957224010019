import React, { useState, useEffect, useRef } from "react";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function TopContracts(props) {
  const [isOpenAge, setIsOpenAge] = useState(false);
  const [isOpenGender, setIsOpenGender] = useState(false);
  const dropdownAgeRef = useRef(null);
  const dropdownGenderRef = useRef(null);
  const [filters, setFilters] = useState({
    age: [],
    gender: [],
    minPrice: "",
    maxPrice: "",
  });

  const applyFilters = () => {
    const filteredContracts = props.contracts.filter((contract) => {
      const minPricePass = !filters.minPrice || contract.minPrice >= parseFloat(filters.minPrice);
      const maxPricePass = !filters.maxPrice || contract.maxPrice <= parseFloat(filters.maxPrice);
      const agePass = filters.age.length === 0 || filters.age.includes(contract.age);
      const genderPass = filters.gender.length === 0 || filters.gender.includes(contract.gender);

      return minPricePass && maxPricePass && agePass && genderPass;
    });

    return filteredContracts;
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFilters({
          ...filters,
          [name]: [...filters[name], value],
        });
      } else {
        setFilters({
          ...filters,
          [name]: filters[name].filter((data) => data !== value),
        });
      }
    } else {
      // For other inputs like minPrice and maxPrice, handle as before
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownAgeRef.current && !dropdownAgeRef.current.contains(event.target)) {
        setIsOpenAge(false);
      }
      if (dropdownGenderRef.current && !dropdownGenderRef.current.contains(event.target)) {
        setIsOpenGender(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <>
      <div className="flex lg:justify-end md:justify-center justify-center">
        <div className="bg-white dark:bg-slate-700 border border-solid border-gray-300 dark:border-none lg:inline-flex md:lg:inline-flex flex items-center rounded-xl lg:flex-row md:flex-row flex-col flex-wrap mt-5 lg:mt-0 md:mt-0 pb-3 lg:pb-0 md:pb-0 lg:w-auto md:w-auto w-full">
          <input
            type="number"
            name="minPrice"
            value={filters.minPrice}
            onChange={handleFilterChange}
            placeholder="Min Price"
            className="form-control block lg:w-[200px] md:w-[200px] w-full px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          />
          <input
            type="number"
            name="maxPrice"
            value={filters.maxPrice}
            onChange={handleFilterChange}
            placeholder="Max Price"
            className="form-control block lg:w-[200px] md:w-[200px] w-full px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          />
          <div ref={dropdownAgeRef} className="relative cursor-pointer">
            <p className="block  lg:w-[200px] md:w-[200px] w-full px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 " onClick={() => setIsOpenAge(!isOpenAge)}>Age</p>
            {isOpenAge &&
              <div className="absolute z-40 bg-white top-11 p-4 shadow rounded-2xl">
                <ul>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Teenagers")} value="Teenagers" />13-17 years</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Young Adults")} value="Young Adults" />18-24 years</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Adults")} value="Adults" />25-34 years</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Young Professionals")} value="Young Professionals" />25-44 years</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Middle-Aged Adults")} value="Middle-Aged Adults" />35-54 years</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Seniors")} value="Seniors" />55-64 years</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="age" onChange={handleFilterChange} type="checkbox" checked={filters.age.includes("Elderly")} value="Elderly" />65 and above</li>
                </ul>
              </div>
            }
          </div>
          <div ref={dropdownGenderRef} className="relative cursor-pointer">
            <p className="block  lg:w-[200px] md:w-[200px] w-full px-6 py-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border-none transition ease-in-out m-0 " onClick={() => setIsOpenGender(!isOpenGender)}>Gender</p>
            {isOpenGender &&
              <div className="absolute z-40 bg-white top-11 p-4 shadow rounded-2xl">
                <ul>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="gender" onChange={handleFilterChange} type="checkbox" checked={filters.gender.includes("M")} value="M" />Male</li>
                  <li className="dark:text-slate-700"> <input className="mr-2" name="gender" onChange={handleFilterChange} type="checkbox" checked={filters.gender.includes("F")} value="F" />Female</li>
                </ul>
              </div>
            }
          </div>
          <button
            className="bg-[#11047A] text-white font-medium mx-2 h-[30px] w-[93px] rounded-xl"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex flex-wrap my-8">
        {applyFilters().length ? (
          applyFilters().map((data, index) => (
            <div className="lg:w-[25%] md:w-[50%] w-full">
              <Link
                to={`/contract/${data.orderDetails.order_id}`}
                key={index}
                className="bg-white dark:bg-[#2B3674] w-[95%] mx-auto block my-2 rounded-[20px] px-6 py-6"
              >
                <div class="relative w-full flex flex-wrap justify-between">
                  {data.category.length > 1
                    ? <img
                      className={`w-full h-full object-cover rounded-2xl `}
                      src={`/dataImages/Multi Data.jpg`}
                      alt="Slider Image"
                    />
                    : <img
                      className={`w-full h-full object-cover rounded-2xl `}
                      src={`/dataImages/${data.category[0]}.jpg`}
                      alt="Slider Image"
                    />
                  }
                </div>

                <div className="tracking-[-0.02em] leading-[30px] mt-4 ml-3 flex justify-between">
                  <div>
                    <h4 className="text-lg text-[#1B2559] dark:text-white font-bold">
                      {data.requestId}
                    </h4>
                    <p className="text-sm text-[#A3AED0]">
                      {data?.daysAgo} days ago
                    </p>
                    <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                      Created Date : {data.orderDetails.createdAt.split("T")[0]}
                    </p>
                    <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                      Listed Date : {data.createdAt.split("T")[0]}
                    </p>
                  </div>
                  <div>
                    <div className="w-24 inline-flex justify-end">
                      <p className="text-[#856DA7] text-center dark:text-blue-600 font-bold text-[13px] bg-[#e0e5f2] px-3 py-0 rounded-lg inline">
                        {data.age === "Teenagers" && "13-17 years"}
                        {data.age === "Young Adults" &&
                          "18-24 years"}
                        {data.age === "Adults" && "25-34 years"}
                        {data.age === "Young Professionals" &&
                          "25-44 years"}
                        {data.age === "Middle-Aged Adults" &&
                          "35-54 years"}
                        {data.age === "Seniors" && "55-64 years"}
                        {data.age === "Elderly" && "65 and above"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tracking-[-0.02em] text-sm leading-6 ml-3 mt-5 flex justify-between items-center">
                  <p className="text-[#856DA7] dark:text-blue-600 font-bold">
                    Price Range : ${data.minPrice} - ${data.maxPrice}
                  </p>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <p className="text-center my-10 font-semibold text-xl">
            No Contracts Found
          </p>
        )}
      </div>
    </>
  );
}
