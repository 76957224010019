const initState = {
    loading: false,
}

const generalReducers = (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'loading':
            return { ...state, loading: payload }
        default:
            return state
    }
}

export default generalReducers
