const OrderActionBtn = ({ status, payload, token, ...rest }) => {
  let color = "",
    title = "";
  switch (status) {
    case "pending":
      color = "bg-[#11047A]";
      title = "Fulfill";
      break;

    case "completed":
      color = "bg-[rgba(255,42,42,0.8)]";
      title = "Make Dispute";
      break;

    case "dispute":
      color = "bg-emerald-500";
      title = "Resolve";
      break;

    default:
      return <></>;
  }

  return (
    <button
      className={`${color} text-white font-medium p-1 px-3 items-center justify-center cursor-pointer rounded-md text-sm`}
      {...rest}
    >
      {title}
    </button>
  );
};

export default OrderActionBtn;
