import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { countries } from "../data/countries";

export default function CreateContract() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  let dataTypes = {
    "Personal Data": {
      Basic: [
        "Email id",
        "First Name",
        "Last Name",
        "Phone Number",
        "Address",
        "Gender",
        "Marital Status",
        "Date Of Birth",
        "Anniversary",
        "Zip Code",
        "Country Code",
      ],
      Career: ["Employer", "Title", "Profession", "School"],
      Activities: ["Diets", "Sports", "Fitness", "Activities"],
      Social: [
        "Twitter Profile",
        "LinkedIn Profile",
        "Facebook Profile",
        "Instagram Profile",
      ],
    },
    "Finance Data": {
      Assets: ["Current Assets", "Fixed Assets"],
      Income: ["Active Income", "Passive Income", "Total Income"],
      Savings: ["Savings", "Start Age Earning", "Savings Ratio"],
      "Spending and Loans": [
        "Short Term Liabilities",
        "Long Term Liabilities",
        "General Monthly Expenses",
      ],
      "Credit Card and Wishlist": [
        "Total EMI",
        "Outstanding Amount",
        "WishList",
      ],
      Finance: [
        "Emergency Funds",
        "Net Worth",
        "Liquidity Ratio",
        "Debt to Assets Ratio",
        "Current Ratio",
        "Financial Freedom Ratio",
        "Debt Service Ratio",
        "Solvency Ratio",
        "Mortgage Limit",
        "Emergency Fund Limit",
        "Expense Ratio",
        "Emergency Fund Ratio",
      ],
    },
    "Home Care Data": {
      "Property Data": [
        "Property Name",
        "Move in date",
        "Address",
        "Postal Address",
        "Country",
        "Year Built",
        "Property Type",
        "Market Value",
        "Average Monthly Spend",
        "Home Equipment",
        "Home Appliances & Amenities",
        "Home Maintenance",
      ],
    },
    "Car Data": {
      "Vehicles Data": [
        "Car Id",
        "VIN Number",
        "Manufacturer Name",
        "Model Name",
        "Manufacturer Year",
        "Current Mileage",
        "Average Monthly Run",
        "Last Service Data",
        "Maintenance Item",
        "Cost",
      ],
    },
    "Travel Data": {
      "Travel Profile": [
        "Trip Length",
        "Activities",
        "Interests",
        "Home Airport",
        "Travel Frequency",
        "Nearest Bus Stop",
        "Family Members",
        "Budget Preference",
        "Budget Per Person",
        "Home Railway Station",
      ],
      "Travel Plans": [],
    },
    "Assist Data": {},
    "Daily Routine Data": {
      "All Data": [
        "Record Data",
        "Place Name",
        "Place Category",
        "Duration In Min",
        "From Time",
        "To Time",
        "Place Street Address",
        "Place Zip Code",
        "Place State",
        "Place Country",
        "Day Of The Week",
        "Day Of The Month",
        "Day Of The Year",
        "Week Of The Year",
        "Month Of The Year",
        "Year",
        "Latitude",
        "Longitude",
        "Tel No 1",
        "Tel No 2",
        "Fax No",
        "Web Site",
        "Hours Of Operation",
      ],
    },
  };
  const [values, setValues] = React.useState({
    effectiveDate: "",
    companyName: "",
    address: "",
    country: "",
    contractDescription: "",
    DRDuration: "",
    DRPeriodType: "",
    DDDuration: "",
    DDPeriodType: "",
    CTDuration: "",
    CTPeriod: "",
    DeliveryDDuration: "",
    DeliveryDPeriodType: "",
    DPDuration: "",
    DPPeriod: "",
    ageRange: "",
    gender: "",
    minimum: "",
    maximum: "",
  });
  const [showModal, setShowModal] = React.useState(false);
  const [errorMsg, setError] = React.useState("");
  const [accordionState, setAccordionState] = React.useState({});
  const [selectedFilters, setSelectedFilters] = React.useState({});

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggleAccordion = async (key) => {
    let allMainKeys = await Object.keys(dataTypes);

    if (allMainKeys.includes(key)) {
      await setAccordionState((prevState) => ({
        [key]: !prevState[key],
      }));
    } else {
      await setAccordionState((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    }
  };

  const toggleFilter = async (category, subCategory, filter) => {
    const newFilters = { ...selectedFilters };

    if (!newFilters[category]) {
      newFilters[category] = {};
    }

    if (!newFilters[category][subCategory]) {
      newFilters[category][subCategory] = [];
    }

    const index = await newFilters[category][subCategory].indexOf(filter);

    if (index !== -1) {
      await newFilters[category][subCategory].splice(index, 1);
    } else {
      await newFilters[category][subCategory].push(filter);
    }
    await setSelectedFilters(newFilters);
  };

  const createContract = async (e) => {
    e.preventDefault();
    if (
      selectedFilters &&
      values.minimum &&
      values.maximum &&
      values.effectiveDate &&
      values.companyName &&
      values.address &&
      values.country &&
      values.contractDescription &&
      values.DRDuration &&
      values.DRPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.CTDuration &&
      values.CTPeriod &&
      values.DeliveryDDuration &&
      values.DeliveryDPeriodType &&
      values.DPDuration &&
      values.DPPeriod
    ) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "post",
        url: `https://web3backend.meinstein.ai/api/requested_data/create`,
        data: {
          category: Object.keys(selectedFilters),
          category_filter: {
            price: 1,
            status: "pending",
            selectedFilters: selectedFilters,
            deliveryDuration: values.DeliveryDDuration,
            deliveryPeriod: values.DeliveryDPeriodType,
            disputeDuration: values.DPDuration,
            disputePeriod: values.DPPeriod,
          },
          minPrice: values.minimum,
          maxPrice: values.maximum,
          effectiveDate: values.effectiveDate,
          buyerCompanyName: values.companyName,
          buyerAddress: values.address,
          buyerCountry: values.country,
          contractDescription: values.contractDescription,
          dataRetentionDuration: values.DRDuration,
          dataRetentionPeriod: values.DRPeriodType,
          dataDeletionDuration: values.DDDuration,
          dataDeletionPeriod: values.DDPeriodType,
          contractTerminationDuration: values.CTDuration,
          contractTerminationPeriod: values.CTPeriod,
          age: values.ageRange,
          gender: values.gender,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          if (response.data.success) await navigate("/dataPackages");
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: true });
        });
    } else setError("Please fill all the fields");
  };

  const reviewContract = async (e) => {
    e.preventDefault();
    if (
      selectedFilters &&
      values.minimum &&
      values.maximum &&
      values.effectiveDate &&
      values.companyName &&
      values.address &&
      values.country &&
      values.contractDescription &&
      values.DRDuration &&
      values.DRPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.CTDuration &&
      values.CTPeriod &&
      values.DeliveryDDuration &&
      values.DeliveryDPeriodType &&
      values.DPDuration &&
      values.DPPeriod
    ) {
      setShowModal(true)
    } else setError("Please fill all the fields");
  };

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Data Seller / Community
        </p>
        <div className="bg-white dark:bg-[#2B3674] shadow-[14px_17px_40px_4px_rgba(112,144,176,0.08)] my-5 rounded-[20px] px-[30px] py-5 tracking-[-0.02em] min-h-[540px]">
          <div>
            <h2 className="text-lg sm:text-2xl text-[#2B3674] dark:text-white font-bold">
              Create New Data Contract
            </h2>

            <div className="text-[#A3AED0] text-sm sm:text-base sm:leading-[26px] mt-5">
              <p>
                To create a data contract on our platform, follow these steps:
              </p>
              <p className="pl-2">
                1. Specify the type of data you want to purchase, the total
                amount in KB you want to pay per user, the minimum and maximum
                amount of KB per user.
              </p>
              <p className="pl-2">
                2. Select the user demographics you are interested in, such as
                age, gender, location, and interests.
              </p>
              <p className="pl-2">
                3. Place the total amount of tokens in escrow to cover the cost
                of the data and the DAO's fee.
              </p>
              <p>
                Once you have completed these steps, your data contract will be
                created and users will be able to accept it and sell their data
                to you. This process allows you to purchase high-quality data
                from users on our platform and helps ensure a fair and
                transparent market for data.
              </p>
            </div>

            <div className="tracking-[-0.02em] text-[#2B3674] dark:text-white mt-14">
              <div className="text-[12.5px] font-normal flex flex-wrap gap-y-3 gap-x-6">
                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Effective Date*</label>
                    <input
                      type="date"
                      name="effectiveDate"
                      onChange={handleChange}
                      value={values.effectiveDate}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Company Name*</label>
                    <input
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      value={values.companyName}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Company Name"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Address*</label>
                    <input
                      type="text"
                      name="address"
                      onChange={handleChange}
                      value={values.address}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Address"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Country*</label>
                    <select
                      name="country"
                      onChange={handleChange}
                      value={values.countryCode}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select your country
                      </option>
                      {countries.length &&
                        countries.map((data, index) => (
                          <option
                            key={index}
                            value={data.name}
                          >
                            {data.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Contract Description*</label>
                    <textarea
                      rows="5"
                      col="20"
                      name="contractDescription"
                      onChange={handleChange}
                      value={values.contractDescription}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Contract Description"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="Buyer shall retain the Data only for the duration necessary to fulfill the purposes defined in this Agreement." className="ml-2">Data Retention Duration*</label>
                    <input
                      type="number"
                      name="DRDuration"
                      onChange={handleChange}
                      value={values.DRDuration}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Data Retention Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="Buyer shall retain the Data only for the duration necessary to fulfill the purposes defined in this Agreement." className="ml-2">Data Retention Period*</label>
                    <select
                      name="DRPeriodType"
                      onChange={handleChange}
                      value={values.DRPeriodType}
                      className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="Buyer shall securely delete or return the Data to Seller within this duration after the agreed-upon retention period." className="ml-2">Data Deletion Duration*</label>
                    <input
                      type="number"
                      name="DDDuration"
                      onChange={handleChange}
                      value={values.DDDuration}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Data Deletion Duration "
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="Buyer shall securely delete or return the Data to Seller within this duration after the agreed-upon retention period." className="ml-2">Data Deletion Period*</label>
                    <select
                      name="DDPeriodType"
                      onChange={handleChange}
                      value={values.DDPeriodType}
                      className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="This Agreement may be terminated by either party within this duration." className="ml-2">
                      Contract Termination Duration*
                    </label>
                    <input
                      type="number"
                      name="CTDuration"
                      onChange={handleChange}
                      value={values.CTDuration}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Contract Termination Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="This Agreement may be terminated by either party within this duration." className="ml-2">Contract Termination Period*</label>
                    <select
                      name="CTPeriod"
                      onChange={handleChange}
                      value={values.CTPeriod}
                      className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="The Seller commits to delivering the Data to the Buyer within this duration." className="ml-2">
                      Delivery Duration*
                    </label>
                    <input
                      type="number"
                      name="DeliveryDDuration"
                      onChange={handleChange}
                      value={values.DeliveryDDuration}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Delivery Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="The Seller commits to delivering the Data to the Buyer within this duration." className="ml-2">Delivery Period*</label>
                    <select
                      name="DeliveryDPeriodType"
                      onChange={handleChange}
                      value={values.DeliveryDPeriodType}
                      className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within this duration of receipt of the Data as a 'Dispute'." className="ml-2">
                      Dispute Duration*
                    </label>
                    <input
                      type="number"
                      name="DPDuration"
                      onChange={handleChange}
                      value={values.DPDuration}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Dispute Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label title="In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within this duration of receipt of the Data as a 'Dispute'." className="ml-2">Dispute Period*</label>
                    <select
                      name="DPPeriod"
                      onChange={handleChange}
                      value={values.DPPeriod}
                      className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium ml-2 my-5">Data Types</p>

                <div className="flex flex-wrap cursor-pointer relative">
                  {Object.keys(dataTypes).map((category) => (
                    <div>
                      <div
                        key={category}
                        className={`inline-block ${accordionState[category]
                          ? "bg-slate-700 text-white py-1"
                          : "text-[#11047A] dark:text-white border-2 border-[#11047A] dark:border-white py-1 font-semibold"
                          } rounded-[16px] font-medium leading-6 text-xs px-3 mb-3 mr-2`}
                      >
                        <h2
                          className="inline-block"
                          onClick={() => toggleAccordion(category)}
                        >
                          {category}
                        </h2>
                      </div>
                      <div className="mb-2 rounded-[16px] absolute z-50 bg-slate-700 w-[250px]">
                        {accordionState[category] && (
                          <div className="py-4 px-2">
                            {Object.keys(dataTypes[category]).map(
                              (subCategory) => (
                                <div
                                  key={subCategory}
                                  className="rounded-[16px] border-2 text-white bg-slate-500  border-[#f7f7f7] mb-1 px-4"
                                >
                                  <h3
                                    onClick={() => toggleAccordion(subCategory)}
                                  >
                                    {subCategory}
                                  </h3>
                                  <div className="ml-2">
                                    {accordionState[subCategory] && (
                                      <ul>
                                        {dataTypes[category][subCategory].map(
                                          (item) => (
                                            <li key={item}>
                                              <div className="flex items-center p-2 ">
                                                <input
                                                  id={`checkbox-item-${item}`}
                                                  type="checkbox"
                                                  checked={selectedFilters[
                                                    category
                                                  ]?.[subCategory]?.includes(
                                                    item
                                                  )}
                                                  onChange={() =>
                                                    toggleFilter(
                                                      category,
                                                      subCategory,
                                                      item
                                                    )
                                                  }
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                />
                                                <label
                                                  htmlFor={`checkbox-item-${item}`}
                                                  className="w-full ms-2 text-xs text-gray-200"
                                                >
                                                  {item}
                                                </label>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <p className="text-sm font-medium ml-2 my-5">
                  User Demographics
                </p>

                <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap gap-y-3 gap-x-6 ml-2">
                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Age Range*</label>
                      <select
                        name="ageRange"
                        onChange={handleChange}
                        value={values.ageRange}
                        className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="" selected disabled>
                          Select Age Range
                        </option>
                        <option value="Teenagers">
                          Teenagers (13-17 years)
                        </option>
                        <option value="Young Adults">
                          Young Adults (18-24 years)
                        </option>
                        <option value="Adults">Adults (25-34 years)</option>
                        <option value="Young Professionals">
                          Young Professionals (25-44 years)
                        </option>
                        <option value="Middle-Aged Adults">
                          Middle-Aged Adults (35-54 years)
                        </option>
                        <option value="Seniors">Seniors (55-64 years)</option>
                        <option value="Elderly">Elderly (65 and above)</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Gender*</label>
                      <select
                        name="gender"
                        onChange={handleChange}
                        value={values.gender}
                        className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="" selected disabled>
                          Select Gender
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium ml-2 my-5">Price Range</p>

                <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap gap-y-3 gap-x-6 ml-2">
                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Minimum*</label>
                      <input
                        type="number"
                        name="minimum"
                        onChange={handleChange}
                        value={values.minimum}
                        className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Enter Minimum"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Maximum*</label>
                      <input
                        type="number"
                        name="maximum"
                        onChange={handleChange}
                        value={values.maximum}
                        className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Enter Maximum"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errorMsg ? (
                <p className="text-sm text-center text-red-700 font-semibold">
                  {errorMsg}
                </p>
              ) : null}

              <div className="flex justify-end">
                <button
                  className="min-w-max text-white bg-[#11047A] rounded-[47px] font-medium text-sm leading-6 my-3 px-7 py-1"
                  onClick={reviewContract}
                >
                  Create Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center lg:pt-[250px] pt-[1000px] lg:pb-[50px] pb-[400px] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 lg:w-[800px] w-[350px] rounded-lg shadow-lg relative flex flex-col bg-white dark:bg-[#2B3674] outline-none focus:outline-none">
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold">Review Data</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="tracking-[-0.02em] text-[#2B3674] dark:text-white">
                    <div className="text-[12.5px] font-normal flex flex-wrap lg:gap-y-3 lg:gap-x-6 gap-y-1 gap-x-4">
                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Effective Date*</label>
                          <input
                            type="date"
                            name="effectiveDate"
                            onChange={handleChange}
                            value={values.effectiveDate}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Company Name*</label>
                          <input
                            type="text"
                            name="companyName"
                            onChange={handleChange}
                            value={values.companyName}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Company Name"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Address*</label>
                          <input
                            type="text"
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Address"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Country*</label>
                          <input
                            type="text"
                            name="country"
                            onChange={handleChange}
                            value={values.country}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Country"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Contract Description*</label>
                          <textarea
                            rows="5"
                            col="20"
                            name="contractDescription"
                            onChange={handleChange}
                            value={values.contractDescription}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Contract Description"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Data Retention Duration*</label>
                          <input
                            type="number"
                            name="DRDuration"
                            onChange={handleChange}
                            value={values.DRDuration}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Data Retention Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Data Retention Period*</label>
                          <select
                            name="DRPeriodType"
                            onChange={handleChange}
                            value={values.DRPeriodType}
                            className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Data Deletion Duration*</label>
                          <input
                            type="number"
                            name="DDDuration"
                            onChange={handleChange}
                            value={values.DDDuration}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Data Deletion Duration "
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Data Deletion Period*</label>
                          <select
                            name="DDPeriodType"
                            onChange={handleChange}
                            value={values.DDPeriodType}
                            className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">
                            Contract Termination Duration*
                          </label>
                          <input
                            type="number"
                            name="CTDuration"
                            onChange={handleChange}
                            value={values.CTDuration}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Contract Termination Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Contract Termination Period*</label>
                          <select
                            name="CTPeriod"
                            onChange={handleChange}
                            value={values.CTPeriod}
                            className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>


                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label title="The Seller commits to delivering the Data to the Buyer within this duration." className="ml-2">
                            Delivery Duration*
                          </label>
                          <input
                            type="number"
                            name="DeliveryDDuration"
                            onChange={handleChange}
                            value={values.DeliveryDDuration}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Delivery Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label title="The Seller commits to delivering the Data to the Buyer within this duration." className="ml-2">Delivery Period*</label>
                          <select
                            name="DeliveryDPeriodType"
                            onChange={handleChange}
                            value={values.DeliveryDPeriodType}
                            className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label title="In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within this duration of receipt of the Data as a 'Dispute'." className="ml-2">
                            Dispute Duration*
                          </label>
                          <input
                            type="number"
                            name="DPDuration"
                            onChange={handleChange}
                            value={values.DPDuration}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Dispute Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label title="In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within this duration of receipt of the Data as a 'Dispute'." className="ml-2">Dispute Period*</label>
                          <select
                            name="DPPeriod"
                            onChange={handleChange}
                            value={values.DPPeriod}
                            className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="text-sm font-medium ml-2 my-5">Data Types</p>

                      <div className="flex flex-wrap cursor-pointer relative">
                        {Object.keys(dataTypes).map((category) => (
                          <div>
                            <div
                              key={category}
                              className={`inline-block ${accordionState[category]
                                ? "bg-slate-700 text-white py-1"
                                : "text-[#11047A] border-2 border-[#11047A] py-1 font-semibold"
                                } rounded-[16px] font-medium leading-6 text-xs px-3 mb-3 mr-2`}
                            >
                              <h2
                                className="inline-block"
                                onClick={() => toggleAccordion(category)}
                              >
                                {category}
                              </h2>
                            </div>
                            <div className="mb-2 rounded-[16px] absolute z-50 bg-slate-700 w-[250px]">
                              {accordionState[category] && (
                                <div className="py-4 px-2">
                                  {Object.keys(dataTypes[category]).map(
                                    (subCategory) => (
                                      <div
                                        key={subCategory}
                                        className="rounded-[16px] border-2 text-white bg-slate-500  border-[#f7f7f7] mb-1 px-4"
                                      >
                                        <h3
                                          onClick={() => toggleAccordion(subCategory)}
                                        >
                                          {subCategory}
                                        </h3>
                                        <div className="ml-2">
                                          {accordionState[subCategory] && (
                                            <ul>
                                              {dataTypes[category][subCategory].map(
                                                (item) => (
                                                  <li key={item}>
                                                    <div className="flex items-center p-2 ">
                                                      <input
                                                        id={`checkbox-item-${item}`}
                                                        type="checkbox"
                                                        checked={selectedFilters[
                                                          category
                                                        ]?.[subCategory]?.includes(
                                                          item
                                                        )}
                                                        onChange={() =>
                                                          toggleFilter(
                                                            category,
                                                            subCategory,
                                                            item
                                                          )
                                                        }
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                      />
                                                      <label
                                                        htmlFor={`checkbox-item-${item}`}
                                                        className="w-full ms-2 text-xs text-gray-200"
                                                      >
                                                        {item}
                                                      </label>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <p className="text-sm font-medium ml-2 my-5">
                        User Demographics
                      </p>

                      <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap lg:gap-y-3 lg:gap-x-6 gap-y-1 gap-x-4 ml-2">
                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Age Range*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">{values.ageRange}</p>
                          </div>
                        </div>

                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Gender*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">{values.gender}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="text-sm font-medium ml-2 my-5">Price Range</p>

                      <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap lg:gap-y-3 lg:gap-x-6 gap-y-1 gap-x-4 ml-2">
                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Minimum*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">{values.minimum}</p>
                          </div>
                        </div>

                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Maximum*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">{values.maximum}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {errorMsg ? (
                      <p className="text-sm text-center text-red-700 font-semibold">
                        {errorMsg}
                      </p>
                    ) : null}

                    <div className="flex justify-end">
                      <button
                        className="min-w-max text-white bg-[#11047A] rounded-[47px] font-medium text-sm leading-6 my-3 px-7 py-1"
                        onClick={createContract}
                      >
                        Create Contract
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
