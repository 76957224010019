import * as React from 'react'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import SellerContract from "../abi/SellerContract.json";

export function Dispute(props) {
  const [error, setError] = React.useState("");

  const {
    config,
  } = usePrepareContractWrite({
    address: "0xaf010E3F8aF28E20683FF104Dc5015D3D174266F",
    abi: SellerContract.abi,
    functionName: 'disputeOrder',
    overrides: {
      from: props.address,
      value: "0",
    },
    args: [3],
  })
  const { data, write } = useContractWrite(config);
  
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     props.Approve();
  //   }, 6000);
  // }, [isLoading, isSuccess])


  const writeFunction = async () => {
    try {
      write();
      setError("");
    } catch (error) {
      console.log(error, "heloo")
      setError("Insufficient balance for this transaction.")
      setTimeout(() => {
        setError("")
      }, 15000);
    }
  }

  return (
    <>
      <button onClick={writeFunction} className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"> Dispute </button>

      {/* {error
        ? <div className='text-white text-[15px] text-center break-all mb-5'>{error} </div>
        : null
      } */}

      {/* {(isError) && ( */}
      {/* <div className='text-white text-xs text-center break-all pt-3'>{(error)?.message} </div> */}
      {/* // <div className='text-white text-xs text-center break-all pt-3'>{((prepareError || error)?.message && (prepareError || error)?.message.split(" ")[0] == "insufficient") ? "Insufficient balance for this transaction." : null} </div> */}
      {/* )} */}
    </>
  )
}