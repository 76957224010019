import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function PrivacyPolicy() {

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <div className="bg-[#f4f7fe] pb-20 min-h-screen w-full dark:bg-slate-700">
          <div className="mt-[31px] px-5 text-[#2B3674] dark:text-white mx-auto container">
            <Link to="/dashboard" className="flex items-center"><MdOutlineKeyboardBackspace className="mr-3" /> Back to Dashboard</Link>
            <h1 className="text-[40px] my-5 text-center">Effective Date: February 26, 2024</h1>
            <h1 className="text-[40px] my-5 text-center">mEinstein Privacy Policy</h1>
            <div className="mb-5">

              <p>Welcome to mEinstein. Your privacy is critically important to us. In our Privacy Policy, we describe the types of information we collect from users, how we use it, and the rights you have regarding your data. This policy is designed to help you understand your privacy rights and to reassure you of mEinstein's commitment to secure and respectful handling of your personal information.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">1. Information Collection and Use:</h3>

              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Registration and Account Management:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Basic Information:</span> When you register for an account on mEinstein, we collect essential information such as your first name, last name, email address, password and zip code. This information facilitates the creation and security of your account, ensuring you can recover your account if necessary.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">How We Use Your Information:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Service Delivery:</span> When you register for an account on mEinstein, we collect essential information such as your first name, last name, email address, password and zip code. This information facilitates the creation and security of your account, ensuring you can recover your account if necessary.</li>
                  <li><span className="font-semibold">Communication:</span> We use your email address to communicate with you about your account, provide customer support, send you service-related notices, and inform you about changes to our policies or services. This may include sending you updates on new features, alerts, or other promotional materials that are part of the service. If you do not wish to receive these communications, you will have the option to opt out or unsubscribe.</li>
                  <li><span className="font-semibold">Service Improvement:</span> We analyze the information we collect to understand user behavior and preferences. This analysis helps us improve the functionality and user experience of mEinstein. For example, by understanding how users interact with our platform, we can introduce new features or make adjustments that enhance usability and satisfaction.</li>
                  <li><span className="font-semibold">Security:</span> Your information assists us in ensuring the integrity and security of our service. It helps us to detect, prevent, and respond to potential threats or violations of our policies.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">User-Generated Content:</h3>
                <p>mEinstein does not store, use, see, sell, or market user-generated content without explicit user consent. Our platform is built on the principle that users maintain full control and ownership over their content. You decide how your content is shared, who can access it, and under what terms.
                  <br />
                  At mEinstein, we respect and protect your right to control your user-generated content. Our platform is designed to ensure that you have full autonomy over the content you create, share, and manage. Here are the key principles and conditions related to your content:</p>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">User Control:</span> You retain control over the content you generate on mEinstein. This includes decisions on how it is shared, who can view it, and under what conditions it can be accessed.</li>
                  <li><span className="font-semibold">No Unauthorized Use: </span> mEinstein does not access, use, see, sell, or market user-generated content without your explicit consent. Any engagement with your content by mEinstein is driven by your choices and permissions.</li>
                  <li><span className="font-semibold">Disclosure under Legal Obligations:</span> mEinstein may be legally required to disclose your content without your prior consent. Such circumstances include:There are circumstances where mEinstein may be legally required to disclose your content without your prior consent. Such circumstances include:
                    <ul className="list-disc ml-5">
                      <li><span className="font-semibold">Compliance with Laws:</span> If we receive a valid legal request (such as a subpoena or court order), we may need to access and disclose the specific content required by law, even if you have not given us permission. We will only provide the minimum content necessary to comply with the legal request.</li>

                      <li><span className="font-semibold">Safety and Security:</span> If there is a compelling need to access or disclose your content to protect the safety and security of our users, prevent fraudulent activities, or respond to potential threats to physical safety, we may do so in accordance with our policies and applicable law. This is only done under strict conditions and in response to clear, legal requirements.</li>
                    </ul></li>
                  <li><span className="font-semibold">Notification:</span>Except where prohibited by law, mEinstein is committed to notifying you if we are required to provide your content to law enforcement or respond to a legal request. We aim to ensure transparency in our actions and give you an opportunity to object to disclosures, where possible.</li>

                  <li><span className="font-semibold">Data Integrity</span>We take steps to ensure that any user-generated content we may access or disclose is handled securely, respecting your privacy and data protection laws. Our goal is to protect your content from unauthorized access or disclosure during these processes.</li>
                  <li><span className="font-semibold">Consent and Choice:</span>At any time, you have the option to modify, limit, or withdraw your consent regarding how we use your information. You can access and update your preferences through your account settings or by contacting our support team.</li>
                </ul>
                <p>By using mEinstein, you acknowledge and agree to these conditions regarding your user-generated content. We encourage you to manage your content and privacy settings carefully to reflect your preferences and legal requirements.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">2. Data Retention:</h3>
              <div className="mt-5">
                <ul className="list-disc ml-5"><li>We retain your personal information only for as long as necessary to provide you with our services and as described in this Privacy Policy. When your information is no longer required for these purposes, we ensure it is securely deleted or anonymized.</li> </ul>
                <p className="mt-5">By engaging with mEinstein, you trust us with your information. We are committed to using it responsibly and for the purpose of enhancing your experience on our platform.</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">3. User Rights and Data Control</h3>
              <p className="ml-5 mt-2">Users have full control and power over their data on mEinstein. This includes the ability to access, modify, and delete their personal information at any time through their account settings.</p>
              <p className="ml-5 mt-2">At mEinstein, we prioritize your privacy and control over your personal data. Our platform empowers you with comprehensive rights in managing your information, consistent with global privacy standards, including the General Data Protection Regulation (GDPR) for our users in the European Union (EU). Here’s an overview of your key rights and how we handle consent:</p>
              <ul className="list-disc ml-5 mt-5">
                <li><span className="font-semibold">Right to Access:  </span> You have the right to access your personal data processed by mEinstein. You can request a copy of your data to review the information we have on file.</li>
                <li><span className="font-semibold">Right to Rectification: </span> If your personal data is inaccurate or incomplete, you have the right to ask us to correct or complete it.</li>
                <li><span className="font-semibold">Right to Be Forgotten (Right to Erasure):</span> You can request the deletion of your personal data when it is no longer necessary for the purposes for which it was collected, among other conditions.</li>
                <li><span className="font-semibold">Right to Object:</span> You have the right to object to the processing of your personal data based on legitimate interests, including profiling. mEinstein will cease processing unless we demonstrate compelling legitimate grounds for the processing.</li>
                <li><span className="font-semibold">Right to Restriction of Processing: </span> You may request the restriction of processing of your personal data under certain conditions, such as if you contest the accuracy of the data or the processing is unlawful.</li>
                <li><span className="font-semibold">Right to Data Portability: </span> f your personal data is inaccurate or incomplete, you have the right to ask us to correct or complete it.</li>

              </ul>
              <p className="mt-5">Consent and Withdrawal</p>
              <ul className="list-disc ml-5 mt-5">
                <li><span className="font-semibold">Obtaining Consent: </span> mEinstein obtains your explicit consent for processing personal data when required. Consent is freely given, specific, informed, and unambiguous. We ensure that the process for giving consent is as easy as withdrawing it.</li>
                <li><span className="font-semibold">Withdrawing Consent: </span> You have the right to withdraw your consent at any time. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. To withdraw your consent, please adjust your account settings or contact us directly.</li>
                <li><span className="font-semibold">Consent for Minors:</span> We pay special attention to protecting the privacy of minors. If our platform is used by individuals under the age of consent in their country, we require consent from a parent or guardian to process the minor's personal data.</li>

              </ul>
              <p className="mt-5">mEinstein is committed to upholding these rights, providing you with control over your data. If you have any questions or wish to exercise any of your rights, please contact us through the provided support channels. Our team is dedicated to assisting you promptly and effectively.</p>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">4. Data Security</h3>
              <p className="ml-5 mt-2">mEinstein is committed to protecting the security of your personal information. We use a variety of security technologies and procedures to help protect your personal information from unauthorized access, use, or disclosure. Despite these measures, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

              <p className="ml-5 mt-2">In alignment with our privacy-first approach, we have structured our data handling practices to maximize user control and security. We understand the importance of protecting personal information and are committed to maintaining the highest standards of data security and privacy compliance.</p>
              <p className="ml-5 mt-2">We prioritize your privacy with local data processing on your device. Essential data is stored on our servers with robust encryption and in compliance with privacy laws. Our Privacy Policy provides further details on our data handling practices.</p>
              <p className="ml-5 mt-2">Below are the key aspects of our data security and privacy measures:</p>
              <p><span className="mt-5 text-[30px]">Local Data Storage: </span>mEinstein is designed with your privacy in mind, which is why we do not store any user data on our servers or in the cloud. All personal data generated or processed by our app resides solely on your mobile device, ensuring you have complete control over your information.
              </p>
              <p><span className="mt-5 text-[30px]">Registration Data:  </span>During the registration process, we collect only the minimal but necessary information required to set up your account. This approach is in line with our principle of data minimization and privacy preservation.
              </p>
              <p><span className="mt-5 text-[30px]">Protection of Consumer Rights:  </span>To safeguard consumer rights and facilitate a trustworthy marketplace, we store essential information about buyers and data contracts on our servers. This practice is crucial for enforcing data contracts and ensuring a reliable transaction environment within the mEinstein marketplace.</p>
              <p><span className="mt-5 text-[30px]">User Data Responsibility:  </span>mEinstein is designed with a strong commitment to user autonomy and privacy, which includes a unique approach to data storage; all user data is stored locally on the user's device. This means that mEinstein does not have access to, nor does it store, back up, or have the ability to restore any user-generated content. Consequently, users are solely responsible for the backup of their data. We strongly recommend regular backups of your device to ensure the safety of your data. In the event of device loss, theft, or app deletion, mEinstein cannot recover or restore personal data, as it is not stored on our servers. This approach underscores our commitment to privacy and data security, but it also places the responsibility for data preservation directly in the hands of our users.</p>
              <p><span className="mt-5 text-[30px]">Device Manufacture’s Responsibility: </span>In the innovative ecosystem of mEinstein, user data security is paramount, and our unique approach places the storage of personal data directly on the user's device. This method harnesses the intrinsic security measures of leading mobile platforms, including Apple's iOS and Google's Android, leveraging their advanced encryption and security protocols to safeguard user information. As such, the responsibility for data breaches falls under the purview of the device manufacturers and their operating systems. mEinstein is dedicated to empowering users with control over their data while ensuring its accessibility remains secure within the confines of the user's own device. Our platform's architecture inherently minimizes the risk of centralized data breaches, thereby aligning with our commitment to privacy and user autonomy. It is important for users to recognize that maintaining the security of their device and adhering to recommended security practices plays a crucial role in protecting their personal data.</p>
              <p><span className="mt-5 text-[30px]">MetaMask Recovery Phrase: </span>For users utilizing MetaMask within our platform, we especially emphasize the importance of securely and redundantly saving your MetaMask Recovery Phrase. This recovery phrase is vital for accessing your MetaMask wallet, and losing it can result in the permanent loss of access to your assets. Please ensure that you store this phrase in a secure location(s) that you can reliably access.</p>
              <p><span className="mt-5 text-[30px]">Incident Response Plan: </span>In the unlikely event of a data breach, we have an incident response plan in place to quickly address and mitigate the impact. We are committed to notifying affected individuals and relevant authorities in compliance with applicable laws and regulations.</p>

              <p className="mt-5">By adhering to these practices, mEinstein aims to provide a secure and privacy-centric platform for our users. We urge all users to take active steps in securing their data, especially regarding backup procedures and safeguarding sensitive recovery phrases, to maintain the highest level of data protection and security.</p>



            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">5. Changes to This Privacy Policy</h3>
              <p className="ml-5 mt-2">mEinstein reserves the right to update or change our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. We will indicate at the top of this Privacy Policy when it was last updated. You are advised to review this Privacy Policy periodically for any changes.</p>

              <p className="ml-5 mt-2">To ensure you are informed of any modifications, we will:</p>
              <ul className="list-disc ml-5 mt-5">
                <li><span className="font-semibold">Notify you via Email: </span> We will send an email to the address associated with your account, detailing any significant changes to the Privacy Policy and what they mean for you.</li>
                <li><span className="font-semibold">In-App Notifications:  </span> For immediate visibility, we will also provide in-app notifications regarding any changes to the Privacy Policy, ensuring that all users, regardless of their email notification settings, are aware of our updated practices.</li>
                <li><span className="font-semibold">Update the Policy on Our Website:</span> The most current version of the Privacy Policy will always be posted on our website, with the effective date clearly marked at the top. We encourage you to review the Privacy Policy periodically to stay informed about how we are protecting the personal information we collect.</li>

              </ul>
              <p className="mt-5">Your continued use of mEinstein after any changes to the Privacy Policy have been made will constitute your acceptance of those changes. If you do not agree to the updated policy, you should discontinue your use of the mEinstein services.</p>
              <p className="mt-5">We understand the importance of privacy and are committed to being transparent about how we handle your personal information. If you have any questions about our Privacy Policy or the information we collect, please do not hesitate to contact us.</p>


            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">6. International Data Transfer</h3>
              <p className="ml-5 mt-2">In the global digital economy, mEinstein operates across international borders. This means that the information we collect may be processed and stored in countries other than where you reside. These international transfers of your personal data are necessary for the provision of our services to you and for the operation of our platform.</p>

              <p className="ml-5 mt-2">We understand the importance of protecting your information when it is transferred internationally. As such, mEinstein takes all necessary steps to ensure that your data is treated securely and in accordance with this Privacy Policy and applicable data protection laws, including:</p>
              <ul className="list-disc ml-5 mt-5">
                <li><span className="font-semibold">Compliance with International Frameworks: </span> We transfer user registration data between countries only if required by law and for operations in a manner that is compliant with international data protection laws. For transfers out of the European Union and other regions with data protection laws, we ensure similar protection is afforded to the data by implementing at least one of the following safeguards:<ul className="list-disc ml-5 mt-5">

                  <li>Data transfer agreements incorporating standard contractual clauses approved by the European Commission or relevant authorities, ensuring that personal data is protected according to the standards required within the EU.</li>
                  <li>Transferring data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission or other relevant authorities.</li>
                  <li>Utilizing any other mechanism under applicable law that provides adequate safeguards for the protection of personal data during its transfer.</li>

                </ul></li>
                <li><span className="font-semibold">Security Measures: </span> Regardless of where your user registration data is processed, mEinstein applies the same protective measures and security controls across our operations. This includes encryption, access controls, and secure data processing practices to prevent unauthorized access and ensure the integrity and confidentiality of your data.</li>
                <li><span className="font-semibold">User Consent: </span> Where applicable, mEinstein may also seek your explicit consent for the transfer of your user registration data outside of your local jurisdiction, after informing you about the possible risks of such transfers for which there may not be adequate safeguards.</li>

              </ul>
              <p className="mt-5">We are committed to ensuring that your data is handled with care and respect, no matter where it is processed. For more information about our data transfer practices or the specific safeguards applied to your personal data, please contact us at [contact information].</p>



            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">7. Contact Us</h3>
              <p className="ml-5 mt-2">If you have any questions about this Privacy Policy or our treatment of your personal information, please contact us at:</p>
              <ul className="list-disc ml-5 mt-5">
                <li><span className="font-semibold">Email: </span> support@meinstein.ai</li>
              </ul>
              <p className="mt-5">WBy using mEinstein, you consent to the practices described in this Privacy Policy.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
