import React from "react";

export default function TermsCondition() {

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto rela">
        <div className="bg-[#f4f7fe] pb-20 w-full dark:bg-slate-700">
          <div className="flex justify-center">
            <div className="bg-white p-2 rounded-2xl mt-10">
              <img src="/img/logo.jpg" className="w-[120px] mb-3" alt="" />
            </div>
          </div>
          <div className="mt-[31px] px-5 text-[#2B3674] dark:text-white mx-auto container">

            <h1 className="text-[40px] my-5 text-center">mEinstein Terms and Conditions</h1>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Welcome to mEinstein!</h3>
              <p>These Terms and Conditions outline the rules and regulations for the use of mEinstein's Services, including our Personal AI and Data Monetization platform. By accessing and using mEinstein, you consent to these terms. If you disagree with any part of these terms, you must not use our services.</p>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">1. Limited Liability and Clarification</h3>
              <p>mEinstein strives to offer cutting-edge services; however, our offerings, including Personal AI recommendations and Data Monetization insights, are provided "as is" and "as available." Despite our efforts, inaccuracies or errors in data analysis, AI-generated content, or monetization strategies may occur. mEinstein, its affiliates, and service providers shall not be liable for any losses or damages arising from these inaccuracies or the reliance on our services. Users should exercise caution and seek independent verification when decisions based on financial, legal, or personal wellbeing are made using mEinstein's outputs.</p>

              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Examples of Potential Inaccuracies:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Data Analysis:</span> Insights generated by our platform, based on user data or external sources, may not always capture the full complexity of the data or may reflect incomplete information.</li>
                  <li><span className="font-semibold">AI Recommendations:</span> Suggestions or advice provided by our AI, whether related to financial investments, health and wellness, or other decision-making areas, are based on algorithms and data at hand. These recommendations might not account for all possible outcomes or personal circumstances.</li>
                  <li><span className="font-semibold">Monetization Strategies:</span> Predictions or strategies for data monetization provided by mEinstein are estimates based on market trends and data analysis. Actual results may vary due to market fluctuations or changes in data valuation.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">User Responsibility:</h3>
                <p>While we strive to ensure the accuracy and usefulness of the information and recommendations provided by mEinstein, the ultimate responsibility for decisions made based on our services rests with you, the user. We encourage you to use mEinstein's tools as one of several resources in your decision-making process. For decisions with significant financial, legal, or personal implications, we strongly recommend seeking additional advice from qualified professionals.</p>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Liability Limitation:</h3>
                <p>In light of the above, mEinstein, including its affiliates and service providers, shall not be held liable for any direct, indirect, incidental, or consequential losses or damages arising from the use of our services, reliance on our recommendations, or inaccuracies in the data or analysis provided. This limitation of liability applies to the fullest extent permitted by law and survives any termination or expiration of this agreement or your use of mEinstein's services.
                  <br />
                  We value transparency and openness in our communications with users. By using mEinstein, you acknowledge and agree to these limitations on liability, understanding that our services are provided "as is" and with the intent to supplement, not replace, your judgment and expertise.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">2. Goodwill and Intent</h3>
              <p>Our core mission is to leverage technology to empower users, operating with the utmost goodwill and positive intentions. We recognize the challenges and limitations inherent in technology and strive to improve continuously.
                <br />
                At mEinstein, our guiding principle and ultimate mission are rooted in empowerment: <span className="font-semibold">"Empowering every user to become the CEO of their data."</span> We believe in harnessing the full potential of personal data and insights, unlocking its true value and utility in the new era of AI and Web3. In a world where "Data is the New Oil," we see every individual as a priceless and endless reservoir of data.</p>

              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Our Vision:</h3>
                <p>mEinstein is not just a platform; it's a movement towards democratizing data ownership and monetization. We envision a future where every user has their own Data Refinery and is empowered to be its CEO. This means providing you with the tools and knowledge to control, manage, and monetize your personal data in a way that was previously unimaginable.</p>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Harnessing Personal Data: </span> We aim to enable users to tap into their own data, understanding its worth and learning how to leverage it for personal gain and societal benefit.</li>
                  <li><span className="font-semibold">Unlocking True Value: </span> Through our innovative platform, users can discover the monetary potential of their data, transforming it from a passive asset into an active income stream.</li>
                  <li><span className="font-semibold">Empowering Users:  </span> Our ultimate goal is to shift the power dynamics in the digital economy, placing control back into the hands of the data creators - you, the users.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Learn More:</h3>
                <p>To dive deeper into our mission and the values that drive us, we invite you to visit our About Us page. Here, you'll find more information about our goals, the team behind mEinstein, and how we're working to change the landscape of personal data management and monetization.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">3. User Discretion and Examples</h3>
              <p>Users should exercise judgment when using mEinstein's Personal AI. For instance, financial decisions, health-related actions, or legal matters should not solely rely on AI recommendations without consulting relevant professionals.
                <br />
                <span className="font-semibold">User discretion is paramount when employing mEinstein's Personal AI for decision-making.</span> While our platform provides valuable insights, it's essential to recognize the context and limitations of automated advice. Below, we outline examples in key areas where discretion is advised, categorized for clarity. It's important to note that these examples are illustrative, not exhaustive, and are intended to guide users on when to seek additional verification or professional advice.</p>

              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Financial Decisions:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Investment Analysis: </span> mEinstein may provide trends and data analysis for potential investment opportunities. However, users should consult with financial advisors to tailor advice to their personal financial situation.</li>
                  <li><span className="font-semibold">Budget Planning: </span> AI-generated suggestions on budgeting can offer new perspectives but cannot capture the full complexity of an individual's financial status. Professional financial planning assistance is recommended for comprehensive advice.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Health and Wellness:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Medical Information: </span> Our platform might offer general health information or wellness tips. Such information should not be seen as medical advice. Always consult healthcare professionals for health-related decisions.</li>
                  <li><span className="font-semibold">Fitness Recommendations: </span> While mEinstein can suggest fitness activities based on general data, individual health conditions and goals vary greatly. Professional guidance from fitness trainers or health advisors is essential.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Legal Matters:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">General Legal Information: </span> mEinstein might provide insights into legal topics. This information is not a substitute for professional legal advice. Engage with legal professionals for guidance on legal matters.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Educational Guidance:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Learning Paths: </span> AI recommendations on courses or learning materials can complement educational planning but should not replace advice from education professionals or academic advisors.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Personal Life Decisions:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Lifestyle Changes: </span> Insights on lifestyle adjustments or personal growth opportunities should be weighed with personal judgment and, when necessary, discussed with experts or trusted advisors.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">The Role of User Discretion:</h3>
                <p>This categorization emphasizes the importance of user discretion across diverse decision-making scenarios. mEinstein's AI is designed to augment, not replace, human decision-making and professional advice. We encourage users to consider these examples as starting points and to actively seek out comprehensive, professional advice in areas critical to their financial, health, legal, educational, and personal decisions.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">4. Marketplace Facilitation and Fee Structure</h3>
              <p>The mEinstein marketplace acts as a facilitator for data monetization, where we charge a service fee for transactions. Detailed fee structures are available on our website. We aim for transparency and fairness in all transactions facilitated through our platform.
                <br />
                <span className="font-semibold">mEinstein's marketplace is designed to empower users to monetize their personal data with ease and transparency.</span> In keeping with our commitment to provide clear and accessible information, we outline our current fee structure and future considerations regarding platform use and transactions.</p>

              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Current Fee Model:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">No Charge for Users: </span> As of now, mEinstein does not charge users for downloading or using the app, nor for participating in any transactions within the marketplace. Our goal is to ensure that our platform remains accessible to all users, encouraging active participation in data monetization without upfront costs.</li>
                  <li><span className="font-semibold">Fees Charged to Data Buyers: </span> While users enjoy free access and transaction capabilities, mEinstein sustains its operations by levying fees on data buyers within the marketplace. This fee structure is designed to facilitate a fair exchange between data providers (our users) and data consumers, ensuring the sustainability of the platform.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Future Considerations:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Potential Transition to Freemium Model: </span> While our services are currently offered free of charge to users, we are exploring the feasibility of transitioning to a freemium model in the future. This model would allow basic access and functionalities for free, with premium features available for a subscription fee. Such a change aims to introduce enhanced features and services for our users while maintaining the core functionalities accessible to everyone.</li>
                  <li><span className="font-semibold">Transparency and Updates: </span> Should there be any changes to our fee structure or the introduction of new charges, we commit to providing clear, advance notice to all users. Detailed and updated information will be readily available on our website. We encourage users to review this information periodically to stay informed about any changes.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Easy Access to Fee Information:</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Website Information: </span>Detailed descriptions of our current fee structure and any future adjustments will be prominently featured on our website. We ensure that this information is easy to find, understand, and review, allowing users and data buyers alike to make informed decisions when engaging with our platform.</li>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Commitment to Transparency</h3>
                <p>mEinstein is dedicated to maintaining a transparent, user-friendly environment where everyone can navigate the data monetization landscape with confidence. We understand the importance of clear communication regarding any potential financial implications for our users and commit to upholding these standards as we evolve.</p>
              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">5.  Third-Party Dependencies</h3>
              <p>mEinstein utilizes third-party services, including payment processors like PayPal and data analytics tools, to enhance our offerings. Our service's effectiveness may depend on these external platforms, and we are not liable for their operational failures.</p>
              <p>mEinstein leverages the capabilities of various third-party services to enhance the functionality and efficiency of our platform. This collaboration allows us to offer a seamless and robust user experience, but it also means we rely on these external services to some extent. Understanding the nature of these dependencies is crucial for our users.</p>

              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Categories of Third-Party Services</h3>
                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Payment Gateways: </span> To facilitate secure and convenient financial transactions within our marketplace, we integrate with leading payment gateways. These platforms ensure the smooth processing of payments between users, data buyers, and other stakeholders.</li>
                  <li><span className="font-semibold">Data Analytics: </span> To provide insightful analytics and improve our services, we utilize sophisticated data analytics tools. These tools help us understand user behavior, marketplace trends, and other critical metrics.</li>
                  <li><span className="font-semibold">Blockchain and Wallet Services:</span> For users engaged in transactions involving digital assets or requiring blockchain verification, we integrate services like MetaMask. This ensures secure and efficient interactions with digital currencies and assets.</li>
                  <li><span className="font-semibold">AI and Machine Learning APIs:</span> To power our Personal AI and Data Monetization platform, we incorporate advanced AI technologies through APIs from industry leaders like OpenAI and others. These integrations allow us to offer cutting-edge features and capabilities to our users.</li>


                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Specific Third-Party APIs</h3>
                <p>We are transparent about our use of specific third-party APIs that are integral to our service offering, including, but not limited to:</p>

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Plaid:  </span> For secure financial data connectivity.</li>
                  <li><span className="font-semibold">MetaMask: </span> For blockchain wallet services and transactions.</li>
                  <li><span className="font-semibold">OpenAI and Bard:</span> For enhancing our AI-driven features and functionalities.</li>


                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">User Understanding and Trust</h3>
                <p>While we choose our third-party service providers carefully, ensuring they meet high standards of security and reliability, it's important for users to be aware of these dependencies. Not naming every entity allows us flexibility and the ability to upgrade or change providers as necessary to improve our services. However, key collaborations, such as with Plaid, MetaMask, OpenAI, and Bard, are highlighted for transparency and to foster trust with our users.</p>
                <p>mEinstein is committed to maintaining a high level of service quality and reliability, even as we depend on these third-party services. We continuously monitor and evaluate our partnerships to ensure they align with our standards and values, aiming to provide the best possible experience to our users.</p>

              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">6. Data Security and Privacy</h3>
              <p>At mEinstein, we are deeply committed to safeguarding your privacy and ensuring the security of your data. In alignment with our privacy-first approach, we have structured our data handling practices to maximize user control and security. We understand the importance of protecting personal information and are committed to maintaining the highest standards of data security and privacy compliance.</p>
              <p>We prioritize your privacy with local data processing on your device. Essential data is stored on our servers with robust encryption and in compliance with privacy laws. Our Privacy Policy provides further details on our data handling practices.</p>
              <p>Below are the key aspects of our data security and privacy measures:</p>

              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Local Data Storage:</span> mEinstein is designed with your privacy in mind, which is why we do not store any user data on our servers or in the cloud. All personal data generated or processed by our app resides solely on your mobile device, ensuring you have complete control over your information.</li>
                  <li><span className="font-semibold">Registration Data:</span> During the registration process, we collect only the minimal but necessary information required to set up your account. This approach is in line with our principle of data minimization and privacy preservation.</li>
                  <li><span className="font-semibold">Protection of Consumer Rights:</span> To safeguard consumer rights and facilitate a trustworthy marketplace, we store essential information about buyers and data contracts on our servers. This practice is crucial for enforcing data contracts and ensuring a reliable transaction environment within the mEinstein marketplace.</li>
                  <li><span className="font-semibold">User Data Responsibility: </span> mEinstein is designed with a strong commitment to user autonomy and privacy, which includes a unique approach to data storage; all user data is stored locally on the user's device. This means that mEinstein does not have access to, nor does it store, back up, or have the ability to restore any user-generated content. Consequently, users are solely responsible for the backup of their data. We strongly recommend regular backups of your device to ensure the safety of your data. In the event of device loss, theft, or app deletion, mEinstein cannot recover or restore personal data, as it is not stored on our servers. This approach underscores our commitment to privacy and data security, but it also places the responsibility for data preservation directly in the hands of our users.</li>
                  <li><span className="font-semibold">Device Manufacture’s Responsibility: </span> In the innovative ecosystem of mEinstein, user data security is paramount, and our unique approach places the storage of personal data directly on the user's device. This method harnesses the intrinsic security measures of leading mobile platforms, including Apple's iOS and Google's Android, leveraging their advanced encryption and security protocols to safeguard user information. As such, the responsibility for data breaches falls under the purview of the device manufacturers and their operating systems. mEinstein is dedicated to empowering users with control over their data while ensuring its accessibility remains secure within the confines of the user's own device. Our platform's architecture inherently minimizes the risk of centralized data breaches, thereby aligning with our commitment to privacy and user autonomy. It is important for users to recognize that maintaining the security of their device and adhering to recommended security practices plays a crucial role in protecting their personal data.</li>
                  <li><span className="font-semibold">MetaMask Recovery Phrase: </span> For users utilizing MetaMask within our platform, we especially emphasize the importance of securely and redundantly saving your MetaMask Recovery Phrase. This recovery phrase is vital for accessing your MetaMask wallet, and losing it can result in the permanent loss of access to your assets. Please ensure that you store this phrase in a secure location(s) that you can reliably access.</li>
                  <p>By adhering to these practices, mEinstein aims to provide a secure and privacy-centric platform for our users. We urge all users to take active steps in securing their data, especially regarding backup procedures and safeguarding sensitive recovery phrases, to maintain the highest level of data protection and security.</p>
                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Security Measures</h3>
                <p>We employ a comprehensive array of security measures designed to protect your data from unauthorized access, alteration, disclosure, or destruction. These measures include, but are not limited to:</p>

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Encryption: </span> All data transmitted to and from our platform is encrypted using industry-standard encryption protocols.</li>
                  <li><span className="font-semibold">Access Control: </span> We implement strict access controls and authentication measures to ensure that only authorized personnel have access to user data.</li>
                  <li><span className="font-semibold">Regular Audits:</span> Our systems undergo regular security audits and vulnerability assessments conducted by independent third-party security experts.</li>


                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Privacy Compliance</h3>
                <p>mEinstein complies with major privacy regulations, including the General Data Protection Regulation (GDPR) in Europe and the California Consumer Privacy Act (CCPA) in the United States, among others. Our compliance ensures that users have control over their personal information, including rights to access, correct, delete, or transfer their data.</p>

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">GDPR Compliance:</span> We provide users in the European Union (EU) and European Economic Area (EEA) with the rights afforded under the GDPR, including data portability and the right to be forgotten.</li>
                  <li><span className="font-semibold">CCPA Compliance: </span> For users in California, we adhere to the CCPA, ensuring transparency about data collection practices and allowing users to opt-out of the sale of their personal information.</li>


                </ul>
              </div>
              <div className="mb-5 ml-5">
                <h3 className="mt-5 text-[26px]">Privacy Policy</h3>
                <p>For a detailed overview of our data handling practices, including how we collect, use, and share your information, please refer to our Privacy Policy. Our Privacy Policy also outlines the steps we take to protect your information and your rights concerning your personal data.</p>
                <p>By using mEinstein, you entrust us with your data, and we take this responsibility seriously. We are continually updating our practices and policies to ensure the highest level of data protection and to comply with evolving privacy laws and standards.</p>

              </div>
            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">7. Amendments and User Notification</h3>
              <p>We reserve the right to amend these terms. Users will be notified of significant changes through email or in-app notifications, ensuring you are always informed.</p>
              <p>mEinstein is committed to maintaining the highest standards of transparency and trust with our users. As part of this commitment, we want to ensure you are fully informed about any changes to our Terms and Conditions, especially those that could significantly impact your rights or how we handle your data. Here's how we've updated our practices around amendments and notifications:</p>

              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Notification of Amendments: </span> We reserve the right to make changes to our Terms and Conditions as our services evolve and as required by law. To keep you informed, we will notify you of any amendments that are significant in nature. These significant changes include, but are not limited to, modifications that affect user rights, data handling practices, and changes to our service fees or functionality.</li>
                  <li><span className="font-semibold">Method of Notification: </span> We will notify you of these important changes through direct communication channels, such as email or in-app notifications. This ensures that you receive timely updates directly from us, allowing you to review the changes and make informed decisions about your continued use of our services.</li>
                  <li><span className="font-semibold">Transparency and Access:</span> In addition to notifying you of significant changes, we will also update the Terms and Conditions on our website. A summary of changes and the effective date will be clearly posted to facilitate your understanding of what has changed. We encourage you to review these updates carefully.</li>
                  <li><span className="font-semibold">Engagement and Feedback: </span> We value your feedback and understand the importance of dialogue. Following any significant update, we welcome your questions and feedback through our support channels. This engagement is vital for ensuring our services continue to meet your needs and expectations.</li>

                </ul>
                <p>mEinstein aims to foster an environment of trust and transparency. We believe that by keeping you well-informed and engaged, we can continue to provide a service that not only meets but exceeds your expectations.</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">8. Intellectual Property Rights</h3>
              <p>mEinstein respects intellectual property rights and expects the same from users. The content generated by users or through our AI remains your property, with certain usage rights granted to mEinstein for operational purposes.</p>
              <p>In our commitment to respecting and protecting the intellectual property rights of our users, we provide clarity on the scope and purpose of the usage rights granted to mEinstein. This is essential for maintaining a transparent and trust-based relationship with our users, ensuring there are no misunderstandings regarding the ownership and use of content generated within our platform.</p>
              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">User-Generated Content:  </span> All content created, uploaded, or shared by users on mEinstein remains the property of the user. This includes text, images, personal data, and any other form of intellectual property.</li>
                  <li><span className="font-semibold">Grant of Limited Usage Rights to mEinstein: </span> By using our platform, you grant mEinstein a non-exclusive, royalty-free, worldwide license to use, reproduce, adapt, and display the content you generate solely for operational purposes. These purposes include, but are not limited to, providing, improving, and developing our services, facilitating data monetization transactions on your behalf, and ensuring the technical functionality of our platform.</li>
                  <li><span className="font-semibold">Scope of Usage Rights: </span> The rights granted to mEinstein are strictly limited to those necessary for the operation and improvement of our services. We do not claim ownership over your content, nor do we use it for unrelated commercial purposes without your explicit consent.</li>
                  <li><span className="font-semibold">Protection and Respect for Intellectual Property: </span> mEinstein is committed to protecting the intellectual property rights of all users. We do not tolerate copyright infringement on our platform and will take appropriate actions to address any violations.</li>
                  <li><span className="font-semibold">Revocation of Usage Rights: </span> Users have the right to revoke these usage rights by deleting their content or terminating their account, subject to any legal obligations that may require us to retain certain information. Upon account termination or content deletion, mEinstein will cease the use of the specific content for which rights have been revoked, except as required to comply with legal obligations or to resolve disputes.</li>

                </ul>
                <p>By specifying the scope of the usage rights granted to mEinstein and ensuring users retain ownership of their intellectual property, we aim to foster a platform that respects and protects the creative and intellectual contributions of our community.</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">9. User Responsibilities and Conduct</h3>
              <p>Users are expected to use mEinstein responsibly and not for any unlawful purposes. Misuse of our services may lead to account suspension or termination.</p>
              <p>In the spirit of fostering a positive and productive environment on mEinstein, we have outlined our expectations regarding user conduct. To ensure clarity and manage expectations effectively, users are encouraged to familiarize themselves with these guidelines, which form the cornerstone of our community standards. For a comprehensive understanding, users are also advised to review our Acceptable Use Policy, available through a direct link on our platform.</p>
              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Acceptable Use: </span> Users are expected to engage with mEinstein services in a manner that is lawful, respectful, and considerate to the community. This includes refraining from posting, sharing, or transmitting content that is illegal, offensive, or intended to harm others.</li>
                  <li><span className="font-semibold">Prohibited Activities:  </span> The following activities are strictly prohibited on mEinstein and may result in immediate account suspension or termination: <ul className="list-disc ml-5">
                    <li> Any form of harassment, bullying, or defamation.</li>
                    <li> Posting or distributing content that infringes upon the intellectual property rights of others.</li>
                    <li> Utilizing the platform for fraudulent purposes or to engage in illegal transactions.</li>
                    <li> Attempting to gain unauthorized access to other user accounts or to the platform's underlying infrastructure.</li>
                    <li> Distributing malware or engaging in activities that could compromise the security of the platform or its users.</li>

                  </ul></li>
                  <li><span className="font-semibold">Responsibility for Account Security: </span> Users are responsible for maintaining the confidentiality of their account information, including passwords. Any actions taken under a user's account will be deemed as actions by the account holder. In the event of unauthorized access, users are expected to notify mEinstein immediately.</li>
                  <li><span className="font-semibold">Data Backup: </span> While mEinstein prioritizes user data security, users are responsible for creating backups of their data. This is crucial for ensuring data recovery in case of loss or damage to the user's device.</li>
                  <li><span className="font-semibold">Compliance with Local Laws:</span> Users must ensure their use of mEinstein complies with all local, state, national, and international laws and regulations.</li>
                  <li><span className="font-semibold">Reporting Violations:</span> mEinstein encourages the community to report any violations of these guidelines or the Acceptable Use Policy. Our team is committed to taking appropriate actions to address reported issues and maintain the integrity of our platform.</li>
                </ul>
                <p>For a detailed account of what constitutes acceptable and unacceptable use of mEinstein, please refer to our Acceptable Use Policy. This policy is designed to provide a comprehensive overview of the behaviors expected from our users to maintain a safe, respectful, and engaging platform for everyone.</p>
                <p>By clearly outlining user responsibilities and acceptable conduct, we aim to create a community that thrives on mutual respect, innovation, and the shared goal of maximizing the potential of personal data and AI technology.</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">10. Termination of Services</h3>
              <p>We may suspend or terminate accounts that violate these terms. Users may request account deletion or data return through our support channels.</p>
              <p>mEinstein reserves the right to terminate or suspend access to our services at any time, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service. While we never take such decisions lightly, we are committed to maintaining a secure and respectful platform for all users. Below, we outline the conditions for termination, and the steps you can take if your access to services is suspended or terminated:</p>
              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Conditions for Termination: </span> Services may be terminated for reasons including, but not limited to, violations of our Terms of Service, engaging in illegal or fraudulent activities, or creating security or technical issues.</li>
                  <li><span className="font-semibold">Appeal Process: </span> If your account is suspended or terminated, you have the right to appeal the decision. To initiate an appeal, please contact us at appeals@mEinstein.ai  with a detailed explanation of your situation. Our team will review your appeal carefully and communicate the next steps within a reasonable timeframe.</li>
                  <li><span className="font-semibold">Data Retrieval Post-Termination:  </span>     • mEinstein understands the importance of your data. Upon termination, you will have the option to retrieve your data for a limited period. To request data retrieval, please contact our support team at support@mEinstein.ai within 30 days of account termination. We will provide instructions on how to download your data securely.
                    Please note, in cases where the termination is due to illegal activities or severe violations of our Terms of Service, data retrieval options may be limited to comply with legal obligations.</li>
                  <li><span className="font-semibold">Finality of Termination: </span> Termination of services results in the deletion of your account and access to any services. Any content or data you have stored on mEinstein will be inaccessible, except for the data retrieval process outlined above.</li>
                </ul>
                <p>mEinstein aims to provide a clear and fair process for the termination of services, ensuring that users are informed and have avenues for recourse. We believe in treating every user with respect and dignity, even in circumstances that necessitate the end of our service relationship.</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">11. International Data Transfer</h3>
              <p>In the global digital economy, mEinstein operates across international borders. This means that the information we collect may be processed and stored in countries other than where you reside by buyers. These international transfers of your personal data are necessary for the provision of our services to you and for the operation of our platform.</p>
              <p>We understand the importance of protecting your information when it is transferred internationally. As such, mEinstein takes all necessary steps to ensure that your data is treated securely and in accordance with this Privacy Policy and applicable data protection laws, including::</p>
              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Compliance with International Frameworks:  </span> We transfer personal data between countries only in a manner that is compliant with international data protection laws. For transfers out of the European Union and other regions with data protection laws, we ensure similar protection is afforded to the data by implementing at least one of the following safeguards:<ul className="list-disc ml-5">
                    <li> Data transfer agreements incorporating standard contractual clauses approved by the European Commission or relevant authorities, ensuring that personal data is protected according to the standards required within the EU.</li>
                    <li> Transferring data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission or other relevant authorities.</li>
                    <li> Utilizing any other mechanism under applicable law that provides adequate safeguards for the protection of personal data during its transfer.</li>

                  </ul></li>
                  <li><span className="font-semibold">Security Measures:</span> Regardless of where your data is processed, mEinstein applies the same protective measures and security controls across our operations. This includes encryption, access controls, and secure data processing practices to prevent unauthorized access and ensure the integrity and confidentiality of your data.</li>
                  <li><span className="font-semibold">User Consent:   </span>     Where applicable, mEinstein may also seek your explicit consent for the transfer of your personal data outside of your local jurisdiction, after informing you about the possible risks of such transfers for which there may not be adequate safeguards.</li>
                </ul>
                <p>We are committed to ensuring that your data is handled with care and respect, no matter where it is processed. For more information about our data transfer practices or the specific safeguards applied to your personal data, please contact us at [contact information].</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">12. Governing Law</h3>
              <p>These terms are governed by the laws of the jurisdiction where mEinstein operates, without regard to its conflict of law provisions.</p>
              <p>The Terms of Service and any separate agreements whereby we provide you services shall be governed and construed in accordance with the laws of [Specific Jurisdiction], the place where mEinstein is incorporated and operates its primary business activities. This choice of law is made without regard to the conflict of law provisions that might direct the application of the laws of another jurisdiction. This means:</p>
              <div className="mb-5 ml-5">

                <ul className="list-disc ml-5">
                  <li><span className="font-semibold">Jurisdictional Clarity: </span> Our choice of MA, USA as the governing law ensures that any legal disputes or interpretations of these terms will be understood and adjudicated according to the laws of this specific location.</li>
                  <li><span className="font-semibold">Conflict of Law:</span> n situations where there might be a conflict between the laws of [Specific Jurisdiction] and those of another jurisdiction, MA,USA's laws will take precedence, ensuring a consistent legal basis for all parties involved.</li>
                  <li><span className="font-semibold">Legal Proceedings:  </span>    Should any dispute arise relating to your use of our services or these terms, such disputes will be resolved in the courts of MA,USA. This includes any legal actions we must take to enforce our terms or protect our legal rights and interests.</li>
                </ul>
                <p>We have selected [Specific Jurisdiction] due to its robust legal framework that supports the fair and equitable resolution of disputes, ensuring that both mEinstein and our users are protected under a clear and just legal system.</p>
              </div>

            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">14. Contact Us</h3>
              <p>For questions or concerns, please contact us at support@mEinstein.ai. </p>


            </div>
            <div className="mb-5">
              <h3 className="mt-5 text-[30px]">Conclusion</h3>
              <p>By using mEinstein, you acknowledge and agree to these Terms and Conditions. We are excited to embark on this journey with you, exploring the potential of Personal AI and Data Monetization.</p>


            </div>

          </div>
        </div>
      </div>
    </>
  );
}
