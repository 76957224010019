import React, { useEffect, useState } from "react";
import { FiBarChart2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { SiElasticstack } from "react-icons/si";
import { TbGavel } from "react-icons/tb";
import axios from "axios";
import { useDispatch } from "react-redux";

export default function CardsTop() {
  const dispatch = useDispatch();
  let userId = localStorage.getItem("login");
  const [stats, setStats] = useState({
    dataSold: "0 KB",
    monthEarning: 0,
    totalEarning: 0,
    totalEarningUSD: 0,
    transaction: 0,
    orders: 0,
    dataPackages: 0,
  });

  const getStats = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      // url: `https://web3backend.meinstein.ai/api/transactions/stats?userID=${userId}`,
      url: `https://web3backend.meinstein.ai/api/transactions/stats?userID=${userId}`,
      headers: {
        "Content-Type": "application/json",
        // "Authorization": token
      },
    })
      .then(async (response) => {
        if (response.data.data) await setStats(response.data.data);
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        await dispatch({ type: "loading", payload: true });
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <div className="flex justify-around xl:justify-start flex-wrap gap-x-2 xl:gap-x-5 gap-y-10">
        <Link
          to="/orders"
          className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex items-center space-x-5 pl-4 rounded-[20px]"
        >
          <p className="w-14 h-14 bg-[#F4F7FE] dark:bg-slate-400 rounded-full flex justify-center items-center">
            <FiBarChart2 className="text-[#856DA7] text-xl" />
          </p>
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              Data Sold
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              {stats.dataSold}
            </p>
          </div>
        </Link>

        <Link
          to="/orders"
          className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex items-center space-x-5 pl-4 rounded-[20px]"
        >
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              Earned this month
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              (BNB){" "}
              {stats.monthEarning
                ? parseFloat(stats.monthEarning).toFixed(5)
                : 0}
            </p>
          </div>
        </Link>
        <Link
          to="/orders"
          className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex items-center pl-4 rounded-[20px]"
        >
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              Total Earnings
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              (BNB){" "}
              {stats.totalEarning
                ? parseFloat(stats.totalEarning).toFixed(5)
                : 0}
            </p>
          </div>
        </Link>

        <div className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex items-center pl-4 rounded-[20px]">
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              USD Equivalent
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              $
              {stats.totalEarningUSD
                ? parseFloat(stats.totalEarningUSD).toFixed(5)
                : 0}
            </p>
          </div>
        </div>

        <Link
          to="/orders"
          className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex space-x-5 items-center pl-4 rounded-[20px]"
        >
          <p className="w-14 h-14 bg-[#F4F7FE] dark:bg-slate-400 rounded-full flex justify-center items-center">
            <AiOutlineShoppingCart className="text-[#856DA7] text-xl" />
          </p>
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              Orders
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              {stats.orders}
            </p>
          </div>
        </Link>

        <Link
          to="/dataPackages"
          className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex space-x-5 items-center pl-4 rounded-[20px]"
        >
          <p className="w-14 h-14 bg-[#F4F7FE] dark:bg-slate-400 rounded-full flex justify-center items-center">
            <SiElasticstack className="text-[#856DA7] text-xl" />
          </p>
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              Data Packages
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              {stats.dataPackages}
            </p>
          </div>
        </Link>

        <Link
          to="/disputes"
          className="bg-white dark:bg-[#2B3674] w-full lg:w-[23%] flex-grow h-24 flex space-x-5 items-center pl-4 rounded-[20px]"
        >
          <p className="w-14 h-14 bg-[#F4F7FE] dark:bg-slate-400 rounded-full flex justify-center items-center">
            <TbGavel className="text-[#856DA7] text-xl" />
          </p>
          <div>
            <p className="font-medium text-sm leading-6 tracking-[-0.02em] text-[#A3AED0]">
              Disputes
            </p>
            <p className="font-bold lg:text-2xl text-lg leading-8 tracking-[-0.02em] text-[#2B3674] dark:text-white">
              0
            </p>
          </div>
        </Link>
      </div>
    </>
  );
}
