import React, { useEffect, useState, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { useAccount, useConnect } from "wagmi";
import { SellDataPackage } from "../contractIntreaction";
import { useDispatch } from "react-redux";
import ReactApexChart from 'react-apexcharts';

export default function TableDP() {
  const dispatch = useDispatch();
  let sellInput = useRef(null);
  let auth = localStorage.getItem("auth");
  const [dataPackages, setDataPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showSellModal, setShowSellModal] = useState(false);
  const [errorMsg, setError] = useState("");
  const [currentPackageID, setCurrentPackageID] = useState("");
  const [priceInBnb, setPriceInBnb] = useState("");
  const [values, setValues] = React.useState({
    price: "",
    usdPrice: "",
    dataHash: "",
    signatureData: "",
    bnbPrice: "",
  });

  const [options] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    yaxis: {
      show: false
    },
    xaxis: {
      labels: {
        show: false
      }
    },
    toolbar: {
      show: false
    }
  });

  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const connectAndSign = async (x) => {
    await connect(x);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const getDataPackages = async (pageIndex = 1) => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/userdata/get_with_paginate?page=${pageIndex}`,
        //url: `http://localhost:4444/api/userdata/get_with_paginate?page=${pageIndex}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          await setDataPackages(response.data.data.data);
          await setMaxPage(response.data.data.totalPage);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  const deleteCurrentPackage = async (dataId) => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "delete",
        url: `https://web3backend.meinstein.ai/api/userdata/${dataId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          await getDataPackages(page);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  useEffect(() => {
    fetchBNBPrice();
  }, []);

  useEffect(() => {
    if (values.price !== "") {
      if (parseFloat(values.price) > 0) {
        const bnbPrice = parseFloat(values.price) / parseFloat(values.bnbPrice);
        if (!isNaN(bnbPrice)) setPriceInBnb(bnbPrice)
        else setPriceInBnb(0);
      } else setPriceInBnb(0);
    } else setPriceInBnb(0);
  }, [values.price]);

  const fetchBNBPrice = () => {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
      .then(response => response.json())
      .then(data => {
        const bnbPrice = parseFloat(data?.binancecoin?.usd);
        setValues({ ...values, "bnbPrice": bnbPrice });
      })
      .catch(error => console.error('Error fetching BNB price:', error));
  };

  function ButtonList({ numberOfButtons }) {
    const buttons = [];

    for (let i = 0; i < numberOfButtons; i++) {
      buttons.push(<button onClick={() => pagination(i + 1)} className={`bg-[#11047A] text-white font-medium h-[30px] w-[30px] rounded-full ${page == i + 1 ? null : "opacity-25"} `} key={i}>{i + 1}</button>);
    }

    return (
      <>
        {buttons}
      </>
    );
  }

  const pagination = async (pageIndex) => {
    await getDataPackages(pageIndex);
    await setPage(pageIndex);
  }

  useEffect(() => {
    getDataPackages();
  }, []);

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Data Seller / Community
        </p>

        <div className="bg-white dark:bg-[#2B3674] w-full rounded-[20px] px-8 py-5 mt-10 mb-24">
          <div className="flex justify-between items-center">
            <h3 className="text-2xl text-[#1B2559] dark:text-white font-bold">
              Data Packages
            </h3>
          </div>

          <div className="mt-5 overflow-x-auto scroll-hide">
            <div className="border-b border-[#E9EDF7] flex items-center justify-between pb-2">
              <div className="min-w-[885px]">
                <div className="w-full flex justify-start text-[#A3AED0] tracking-[-0.02em] leading-6 text-sm font-medium ml-3">
                  <div className="flex justify-center items-center w-[22%]">
                    <p>Name </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex justify-center items-center w-[18%]">
                    <p>Size</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex justify-center items-center  w-[14%]">
                    <p>Type</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex justify-center items-center w-[19%]">
                    <p>Date Created</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-[1050px] w-[900px]">
              <table className="table-fixed border-separate border-spacing-y-2 w-full">
                <tbody className="text-[#2B3674] dark:text-white tracking-[-0.02em] leading-6 text-sm font-bold text-center">
                  {dataPackages && dataPackages.length ? (
                    <>
                      {dataPackages.map((data, index) => (
                        <tr key={index} className="flex items-center my-4">
                          <td className="w-[20%]">{data.data_name}</td>
                          <td className="w-[13%]">{data.data_size}</td>
                          <td className="w-[14%]">{data.data_type}</td>
                          <td className="w-[13%]">{data.data_from.split("T")[0]}</td>
                          <td className="w-[13%]">
                            {data.orderHistory && data.orderHistory.length > 1
                              ? <ReactApexChart options={options} series={[{
                                name: "BNB Price",
                                data: data.orderHistory
                              }]}
                                type="line" height={100} />
                              : null
                            }
                          </td>
                          <td className="space-x-2 w-[28%]">
                            <button
                              className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
                              onClick={() => {
                                setCurrentPackageID(index);
                                setShowSellModal(true);
                                setTimeout(() => {
                                  if (sellInput.current) sellInput.current.focus();
                                }, 1000)
                              }}
                            >
                              Sell
                            </button>
                            <button
                              className="bg-red-600 text-white font-medium h-[30px] w-[93px] rounded-full"
                              onClick={() => {
                                deleteCurrentPackage(data.id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p className="text-center font-semibold py-6 w-full text-[#161319] dark:text-white">
                      No Data Package Found
                    </p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-5 flex justify-center space-x-3">
            <ButtonList numberOfButtons={maxPage} />
          </div>
        </div>
      </div>
      {showSellModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none modalOuter">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 w-[350px] text-black font-saira rounded-lg shadow-lg relative flex flex-col bg-white dark:bg-[#2B3674] outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold text-[#161319] dark:text-white">Sell</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-[#161319] dark:text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowSellModal(false)}
                  >
                    <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {isConnected ? (
                    <div>
                      <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                        <label>Price*</label>
                        <input
                          ref={sellInput}
                          type="number"
                          name="price"
                          onChange={handleChange}
                          className="form-control block w-full rounded-xl px-4 py-2 text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-slate-700 bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-gray-100 focus:border-blue-600 focus:outline-none"
                        />
                        <p className="mt-3">Price in BNB: {priceInBnb ? priceInBnb.toFixed(4) : 0}</p>
                      </div>
                      {errorMsg ? (
                        <p className="text-sm text-center my-3 text-red-700 font-semibold">
                          {errorMsg}
                        </p>
                      ) : null}
                      {values.price > 0 ? (
                        <SellDataPackage
                          address={address}
                          price={priceInBnb ? priceInBnb.toFixed(6) : 0}
                          dataPackage={dataPackages[currentPackageID]}
                          setShowSellModal={setShowSellModal}
                        />
                      ) : (
                        <button
                          type="button"
                          className="bg-[#11047A] text-white font-medium h-[30px] px-5 rounded-full"
                          onClick={() =>
                            setError("Please fill all the fields.")
                          }
                        >
                          Sell
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center">
                      <>
                        {connectors
                          .filter((x) => x.ready && x.id !== connector?.id)
                          .map((x) => (
                            <>
                              {x.name === "MetaMask" ? (
                                <button
                                  type="button"
                                  className="bg-[#11047A] text-white font-medium h-[30px] px-5 rounded-full"
                                  key={x.id}
                                  onClick={async () => {
                                    await connectAndSign({ connector: x });
                                  }}
                                >
                                  Connect MetaMask
                                  {isLoading &&
                                    x.id === pendingConnector?.id &&
                                    " (connecting)"}
                                </button>
                              ) : null}
                            </>
                          ))}
                        {error ? (
                          <p className="text-sm text-center my-3 text-red-700 font-semibold">
                            {error}
                          </p>
                        ) : null}
                        {connectors.filter(
                          (x) => x.ready && x.id !== connector?.id
                        ).length ? null : (
                          <p className="text-sm text-center text-red-700 font-semibold">
                            <a
                              className="text-[#856DA7]"
                              href="https://metamask.io/download/"
                              target="_blank" rel="noreferrer"
                            >
                              Install Metamask
                            </a>
                          </p>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
