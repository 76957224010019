import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useAccount } from "wagmi";

export default function DownloadData() {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  const params = useParams();
  const { orderId } = params;

  const { address, isConnected } = useAccount();

  const [contract, setContract] = useState("");

  const getOrdersForBuyer = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      // url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForBuyer`,
      url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForBuyer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        const currentContract = response.data.data.find(
          (contract) => contract.orderDetails.order_id === orderId
        );

        await setContract(currentContract);
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    getOrdersForBuyer();
  }, []);

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-slate-700">
          <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
            <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
              Data Seller / Community
            </p>
            <div className="bg-white dark:bg-[#2B3674] my-5 rounded-[20px] px-[30px] py-5 tracking-[-0.02em] h-full lg:h-[800px] border border-black">
              <div className="flex flex-col justify-between space-y-2 h-full">
                <div>
                  <h2 className="text-2xl text-[#2B3674] dark:text-white font-bold">
                    Contract Details
                  </h2>
                  <div className="text-[#A3AED0] leading-[26px] mt-2 mb-5">
                    <p>
                      This contract was created by Seller ID:{" "}
                      <span className="text-[#39B8FF]">
                        {contract ? contract.userId : ""}
                      </span>
                      , Contract ID:{" "}
                      <span className="text-[#39B8FF]">
                        {contract ? contract.orderDetails.order_id : ""}
                      </span>{" "}
                    </p>
                    <p>{contract ? contract.contractDescription : ""}</p>
                  </div>

                  <div className="rounded-2xl shadow-[0_18px_40px_rgba(112,144,176,0.12)] mt-5 h-[90px] relative tracking-[-0.02em]">
                    <div className="absolute w-2/6 sm:w-1/5 h-full flex flex-col justify-center pl-[22px]">
                      <p className="text-[#A3AED0] text-xs sm:text-sm">Name</p>
                      <p className="text-[#2B3674] dark:text-white text-sm sm:text-base">
                        {contract ? contract.data_name : ""}
                      </p>
                    </div>
                    <div className="w-2/3 sm:w-4/5 h-full shadow-[0_18px_40px_rgba(112,144,176,0.12)] rounded-2xl absolute right-0">
                      <div className="h-full w-full flex flex-col justify-center pl-[22px]">
                        <p className="text-[#A3AED0] text-xs sm:text-sm">
                          Buyer Address
                        </p>
                        <p className="text-sm sm:text-base">
                          {contract ? contract.orderDetails.buyer : ""}{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-2xl shadow-[0_18px_40px_rgba(112,144,176,0.12)] mt-5 h-[90px] relative tracking-[-0.02em]">
                    <div className="absolute w-1/2 sm:w-2/5 min-[1170px]:w-1/5 h-full flex flex-col justify-center pl-[22px]">
                      <p className="text-[#A3AED0] text-xs sm:text-sm">
                        Data Size
                      </p>
                      <p className="text-sm sm:text-base">
                        {contract ? contract.data_size : ""}
                      </p>
                    </div>
                    <div className="w-1/2 sm:w-3/5 min-[1170px]:w-4/5 h-full shadow-[0_18px_40px_rgba(112,144,176,0.12)] rounded-2xl absolute right-0">
                      <div className="h-full w-full flex flex-col justify-center pl-[22px]">
                        <p className="text-[#A3AED0] text-xs sm:text-sm">
                          Seller Address
                        </p>
                        <p className="text-sm sm:text-base">
                          {contract ? contract.orderDetails.seller : ""}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {isConnected ? (
                  <a
                    href={contract ? contract.data_url : ""}
                    download="Data"
                    target="_blank"
                    rel="noreferrer"
                    className="w-full sm:w-[246px] h-[62px] text-white bg-[#11047A] rounded-full font-medium text-lg flex items-center justify-center"
                  >
                    Download
                  </a>
                ) : (
                  <p className="font-semibold text-red-600 text-center">
                    Please connect metamask first.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
