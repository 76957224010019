import * as React from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
} from "wagmi";
import BuyerContract from "../abi/BuyerContract.json";
import { useDispatch } from "react-redux";
import Web3 from "web3";

export function SellerSellDataOnBuyerContract(props) {
  const dispatch = useDispatch();
  const [errorMsg, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [signature, setSignature] = React.useState("");
  const [web3, setWeb3] = React.useState(null);

  React.useEffect(() => {
    // Initialize Web3 instance
    setWeb3(
      new Web3(
        new Web3.providers.HttpProvider(
          `https://data-seed-prebsc-1-s1.bnbchain.org:8545`
        )
      )
    );
  }, []);

  const getAddressNonce = async (address) => {
    try {
      if (!web3) return null;
      let contract = new web3.eth.Contract(
        BuyerContract.abi,
        "0x097854acF4949D97210E0432c979bf1dA6B10e20"
      );
      var nonce = await contract.methods.nonce(address).call();
      return parseInt(nonce);
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  const generateHash = async () => {
    if (props.address && props.orderId) {
      const privateKey =
        "0x2563eaf2a274a6e0e0cfc8d0bb44372f13c886173260344182315aad0d78c91e";
      let nonce = await getAddressNonce(props.address) + 1;

      const messageHash = await web3.utils.soliditySha3(
        props.orderId,
        props.address,
        nonce
      );
      console.log(props.orderId,
        props.address,
        nonce);

      const signatureData = await web3.eth.accounts.sign(
        messageHash,
        privateKey
      );
      console.log(signatureData.signature);
      
      setSignature(signatureData.signature);
    }
  };

  React.useEffect(() => {
    generateHash();
  }, [props.address, props.orderId]);

  React.useEffect(() => {
    generateHash();
  }, []);

  const { config } = usePrepareContractWrite({
    address: "0x097854acF4949D97210E0432c979bf1dA6B10e20",
    abi: BuyerContract.abi,
    functionName: "fulfillOrder",
    overrides: {
      from: props.address,
      value: "0",
    },
    args: [props.orderId, signature],
  });
  const { data, isSuccess, error, write } = useContractWrite(config);

  React.useEffect(() => {
    if (isSuccess) {
      dispatch({ type: "loading", payload: false });
      setSuccess("Order Sell Succesfully");
      setTimeout(() => {
        setSuccess("");
      }, 15000);
    }
  }, [isSuccess]);

  const writeFunction = async () => {
    try {
      await dispatch({ type: "loading", payload: true });
      await write();
      setError("");
      await setTimeout(() => {
        dispatch({ type: "loading", payload: false });
      }, 5000);
    } catch (error) {
      console.log(error);
      await dispatch({ type: "loading", payload: false });
      await setError("Insufficient balance for this transaction.");
      await setTimeout(() => {
        setError("");
      }, 15000);
    }
  };

  return (
    <>
      <button
        onClick={writeFunction}
        className="w-full sm:w-[246px] h-[62px] text-white bg-[#11047A] rounded-full font-medium text-lg"
      >
        Sell
      </button>

      {errorMsg ? (
        <div class="bg-red-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p class="text-center">{error}</p>
        </div>
      ) : null}

      {success ? (
        <div class="bg-green-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p class="text-center">{success}</p>
        </div>
      ) : null}
    </>
  );
}
