import * as React from "react";
import { usePrepareContractWrite, useContractWrite } from "wagmi";
import { ethers } from "ethers/lib/ethers";
import { ethers as ether } from "ethers";
import Web3 from "web3";
import BuyerContract from "../abi/BuyerContract.json";
import { useDispatch } from "react-redux";

export function BuyerSellDataPackage(props) {
  const dispatch = useDispatch();
  const fee_in_Eth = 0.000001;
  const fee_in_wei = 1000000000000;
  const [errorMsg, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [bulkOrders, setBulkOrders] = React.useState([]);
  const [values, setValues] = React.useState({
    dataHash: "",
    signatureData: "0x3f873232db59625407cd8d940c288bcce28863def08cdbc6b28c3c903dafc4402e97fc95613b86ce357534ef880db012a31e945c759d4c491aa1d1cea531285a1c",
  });
  const [web3, setWeb3] = React.useState(null); // Web3 instance state

  React.useEffect(() => {
    // Initialize Web3 instance
    setWeb3(
      new Web3(
        new Web3.providers.HttpProvider(
          `https://data-seed-prebsc-1-s1.bnbchain.org:8545`
        )
      )
    );
  }, []);

  function toPlainString(num) {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  }
  let amount = props.price > 0 ? parseFloat(props.price) * 1e18 : 0;
  let amountInWei = ether.utils.parseEther(`${parseFloat(props.price)}`);
  amountInWei = amountInWei.toString();
  amountInWei = parseInt(amountInWei);
  let count = props.count ?parseInt(props.count) :1;
  console.log(count, "count");

  const getAddressNonce = async (address) => {
    try {
      if (!web3) return null;
      let contract = new web3.eth.Contract(
        BuyerContract.abi,
        "0x097854acF4949D97210E0432c979bf1dA6B10e20"
      );
      var nonce = await contract.methods.nonce(address).call();
      return parseInt(nonce);
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }
  let ordersData = [];

  const generateOrdersData = async () => {
    let orders = [];
    for (let i = 0; i < count; i++) {
      let signatureData = await generateHash(i);
      let data = [
        amountInWei,
        fee_in_wei,
        props.dataPackage.dataHash,
        signatureData,
      ];
      orders = [...orders, data];
    }
    ordersData = orders;
    await setBulkOrders(ordersData);
    console.log(ordersData);
  };


  const generateHash = async (index) => {
    if (props.dataPackage && props.price) {
      const privateKey =
        "0x2563eaf2a274a6e0e0cfc8d0bb44372f13c886173260344182315aad0d78c91e";
      const dataHash = props.dataPackage.dataHash;
      let nonce = await getAddressNonce(props.address) + index;

      const messageHash = await web3.utils.soliditySha3(
        dataHash,
        amountInWei,
        fee_in_wei,
        nonce
      );

      const signatureData = await web3.eth.accounts.sign(
        messageHash,
        privateKey
      );
      return signatureData.signature;
    }
  };


  React.useEffect(() => {
    generateOrdersData();
  }, [props.price, props.count]);

  if (amount) {
    amount = ether.BigNumber.from(toPlainString(amount));
  }

  const { config } = usePrepareContractWrite({
    address: "0x097854acF4949D97210E0432c979bf1dA6B10e20",
    abi: BuyerContract.abi,
    functionName: "createOrders",
    overrides: {
      from: props.address,
      value: ether.utils.parseEther(`${(parseFloat(props.price) + fee_in_Eth)*count}`),
    },
    args: [bulkOrders],
  });
  const { data, isSuccess, error, write } = useContractWrite(config);

  React.useEffect(() => {
    console.log(isSuccess, data);
    if (isSuccess) {
      dispatch({ type: "loading", payload: false });
      setSuccess("Order Created Successfully");
      setTimeout(() => {
        setSuccess("");
      }, 15000);
    }
  }, [isSuccess]);

  const writeFunction = async () => {
    try {
      await dispatch({ type: "loading", payload: true });
      await write();
      setError("");
      setTimeout(() => {
        dispatch({ type: "loading", payload: false });
      }, 5000);
    } catch (error) {
      console.log(error);
      await dispatch({ type: "loading", payload: false });
      setError("Insufficient balance for this transaction.");
      setTimeout(() => {
        setError("");
      }, 15000);
    }
    props.setShowSellModal(false);
  };

  return (
    <>
      <button
        onClick={writeFunction}
        className="bg-[#11047A] text-white font-medium h-[30px] w-[93px] rounded-full"
      >
        List
      </button>

      {errorMsg ? (
        <div className="bg-red-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p className="text-center">{error}</p>
        </div>
      ) : null}

      {success ? (
        <div className="bg-green-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p className="text-center">{success}</p>
        </div>
      ) : null}
    </>
  );
}
